import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import 'assets/scss/pageLayout.scss';
import history from 'routing/history';
import Button from 'components/Button/Button';
import Avatar from 'components/Avatar/Avatar';
import useModal from 'components/Modal/hook/useModal';
import Title from 'components/Title/Title';

const Header = () => {
    const { t } = useTranslation();
    const cookies = useCookies(['Auth'])[0];
    const removeCookie = useCookies(['Auth'])[2];
    const { showAlert } = useModal();

    const logout = () => {
        removeCookie('Auth', { path: '/' });
        history.push('/login');
    };

    const openModalLogout = () => {
        showAlert({
            showCancel: true,
            cancelBtnText: t('header.logout.cancel'),
            confirmBtnText: t('header.logout.confirm'),
            content: t('header.logout.message'),
            onConfirm: () => logout()
        });
    };

    return (
        <div id='header' className='header d-flex align-items-center justify-content-between'>
            <Title />
            <div className='d-flex align-items-center'>
                <div className='px-3'>
                    <Avatar title={cookies?.Auth?.username?.toUpperCase().charAt(0)} />
                </div>
                <Button
                    type='button'
                    title=''
                    className='btn-disconnect'
                    onClick={openModalLogout}
                />
            </div>
        </div>
    );
};

export default Header;
