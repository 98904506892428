import { ServiceType } from 'interfaces/services';
import React, { createContext, ReactNode, useReducer } from 'react';

// type TotalCount = {
//     type: 'VIDEO' | 'PICTURE';
//     size: string;
//     totalCount: string;
//   };

// export type CallsPerPeriod = {
//     type: 'VIDEO' | 'PICTURE';
//     searchKey: string;
//     date: string;
//     totalCount: string;
//     size: string;
//   };

// export type ConsumptionData = {
//     totalCounts: TotalCount[];
//     totalCountsPerPeriod: CallsPerPeriod[];
//   };

// export interface ServiceType {
//     uuid: string;
//     searchKey: string;
//     name: string;
//     moCost: number;
//     serviceCostFactor: number;
//     freePlanThreshold: number;
//     recurrentCost: number;
// }

export type IinitialState = {
    refetch: boolean,
    services: ServiceType[] | null;
    error: any;
  };

const initialState: IinitialState = {
    refetch: false,
    error: null,
    services: null
};

const servicesContext = createContext<{
    state: IinitialState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as IinitialState,
    dispatch: () => null
});

const reducer = (state: any, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case 'INIT_SERVICES':
            return {
                ...state,
                services: payload
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                error: payload
            };
        case 'HANDLE_REFETCH': {
            return {
                ...state,
                refetch: !state.refetch
            };
        }
        default:
            return state;
    }
};

const ServicesProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <servicesContext.Provider value={{ state, dispatch }}>{children}</servicesContext.Provider>;
};

export { servicesContext, ServicesProvider };
