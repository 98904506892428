import React from 'react';
import { FieldProps } from 'formik';
import 'assets/scss/datePicker.scss';
import 'assets/scss/appForm.scss';

interface ICustomFormFieldProps {
    placeholder?: string;
    label?: string;
    customClass?: string;
    disabled?: boolean;
}

const AppFormDatePicker = ({
    label,
    customClass,
    disabled,
    field: { name, onBlur, onChange, value },
    form: { setFieldTouched },
    ...props
}: ICustomFormFieldProps & FieldProps) => {
    const blur = () => {
        setFieldTouched(name);
        onBlur(name);
    };
    return (
        <div className="date-picker">
            <label className="mb-1">{label}</label>
            <input type="date" onChange={onChange} onBlur={blur} value={value} name={name} disabled={disabled} {...props} />
        </div>
    );
};

export default AppFormDatePicker;
