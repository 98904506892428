import React, { createContext, ReactNode, useReducer } from 'react';
import { Option } from 'interfaces/option';
import { Action } from 'interfaces/action';
import { YearlyAggregatesCalendarData } from 'context/consumptionContext';
import moment from 'moment';

export interface CalendarData {
    apiCallsPerYear: any | null;
    consumptionPerYear: any | null;
    identityPerYear: any | null;
    elemToDisplay: any;
    isLoaded: boolean;
    searchKey: string | null;
}

interface ICalendarContext {
    data: CalendarData;
    data2: YearlyAggregatesCalendarData[];
    currentYear: string;
    yearlyAggregates: YearlyAggregatesCalendarData | null;
    options: Array<Option>;
    selected: Option;
    show: boolean;
}

const initialState: ICalendarContext = {
    data2: [],
    yearlyAggregates: null,
    currentYear: moment().format('YYYY'),
    data: {
        apiCallsPerYear: null,
        consumptionPerYear: null,
        identityPerYear: null,
        elemToDisplay: [],
        isLoaded: false,
        searchKey: null,
    },
    options: [
        { label: 'Consumption', value: 'consumption' },
        { label: 'API Call', value: 'apiCalls' },
        { label: 'Face recognition', value: 'identity' },
    ],
    selected: { label: 'Consumption', value: 'consumptionPerYear' },
    show: false,
};

const calendarContext = createContext<{
    state: ICalendarContext;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as ICalendarContext,
    dispatch: () => null,
});

const reducer = (state: ICalendarContext, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'INIT_DATA':
            return {
                ...state,
                data: payload,
            };
        case 'HANDLE_CHANGE_YEAR_SELECTED': {
            return {
                ...state,
                currentYear: payload,
            };
        }
        case 'INIT_DATA_2':
            return {
                ...state,
                data2: payload,
            };
        case 'HANDLE_SHOW':
            return {
                ...state,
                show: payload,
            };
        case 'HANDLE_OPTIONS': {
            return {
                ...state,
                options: payload,
            };
        }
        case 'HANDLE_SELECTED': {
            return {
                ...state,
                selected: payload,
            };
        }
        default:
            return state;
    }
};

const CalendarProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <calendarContext.Provider value={{ state, dispatch }}>{children}</calendarContext.Provider>;
};

export { calendarContext, CalendarProvider };
