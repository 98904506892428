import React, { createContext, ReactNode, useReducer } from 'react';
import { ClientSupport } from 'interfaces/client';
import { ClientService } from 'interfaces/services';

interface IClientServicesState {
    support: ClientSupport[] | null;
    services: ClientService[] | null;
    error: any;
    refetch: boolean;
    apiServicesActives: boolean;
}

const initialState: IClientServicesState = {
    support: null,
    services: null,
    error: null,
    refetch: false,
    apiServicesActives: false
};

const clientServicesContext = createContext<{
    state: IClientServicesState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as IClientServicesState,
    dispatch: () => null
});

const reducer = (state: any, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case 'INIT_CLIENT_SERVICES':
            return {
                ...state,
                support: payload.support,
                services: payload.services,
                apiServicesActives: payload.apiServicesActives
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                error: payload
            };
        case 'HANDLE_REFETCH': {
            return {
                ...state,
                refetch: !state.refetch
            };
        }
        default:
            return state;
    }
};

const ClientServicesProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <clientServicesContext.Provider value={{ state, dispatch }}>{children}</clientServicesContext.Provider>;
};

export { clientServicesContext, ClientServicesProvider };
