import React, { useState } from 'react';
import 'assets/scss/dashboard.scss';
import CustomSelect from 'components/Select/Select';
import { Option } from 'interfaces/option';
import { Card } from 'components/Cards/Card';
interface DashboardCardProps {
    withBorder?: boolean;
    style?: React.CSSProperties;
}
// Render a card extending basic card with any children
export const DashboardCard: React.FC<DashboardCardProps> = ({ children, style, withBorder = true }) => {
    return (
        <div style={style} className={`card-container ${withBorder ? 'card-container--border' : ''}`}>
            {children}
        </div>
    );
};

type DashboardIconCardProps = {
    title: string;
    subtitle?: string;
    data: string | number | Element | undefined;
    icon: React.ReactElement;
    color?: string;
    id?: string;
    backgroundActive?: boolean;
    onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
};

// Render a card extending basic card with an icon on the left
export const DashboardIconCard: React.FC<DashboardIconCardProps> = ({ id, title, data, subtitle, icon, color = '#F50A6B', backgroundActive, onMouseOver }) => {
    return (
        <div onMouseEnter={onMouseOver} id={id} className={`card-container card-container--icon ${backgroundActive ? 'active' : ''}`}>
            <div className="icon">{icon}</div>
            <div className={`content ${data && data.toString().length > 5 ? 'content--large' : ''}`}>
                <div className="title">{title}</div>
                <div className="data" style={{ color: color }}>
                    <h3>{data}</h3>
                    <span style={{ color: color }}>{subtitle}</span>
                </div>
            </div>
        </div>
    );
};

type RenderChartWithSelectProps = {
    title: string;
    options: Option[];
    value: Option;
    onChange: (newOption: Option, dataKey: 'year' | 'month') => void;
    dataKey: 'year' | 'month';
};

export const RenderChartWithSelect: React.FC<RenderChartWithSelectProps> = ({ title, options, value, onChange, dataKey, children }) => {
    const [selected, setSelected] = useState(value);

    const handleChange = (newValue: unknown) => {
        onChange(newValue as Option, dataKey);
        setSelected(newValue as Option);
    };

    return (
        <div className="dashboard-section-container">
            <div className="d-flex justify-content-between align-items-center">
                <h3>{title}</h3>
                <CustomSelect onChange={handleChange} options={options} value={selected} />
            </div>
            {children}
        </div>
    );
};
