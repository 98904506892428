import React, { Suspense } from 'react';
// Routing
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'routing/PrivateRoute';
import history from 'routing/history';
import Compose from 'routing/Compose';

// Providers
import { CookiesProvider } from 'react-cookie';
import { TableProvider } from 'components/Table/context/tableContext';
import { LoadingProvider } from 'components/Loader/context/loaderContext';
import { ModalProvider } from 'components/Modal/context/modalContext';
import { TitleProvider } from 'components/Title/context/titleContext';
import { ConsumptionProvider } from 'context/consumptionContext';
import { ClientServicesProvider } from 'context/clientServicesContext';
import { ServicesProvider } from 'context/servicesContext';
import { CalendarProvider } from 'components/Calendar/context/calendarContext';
import { ToastsProvider } from 'context/toastContext';
import { clientManagementProvider } from 'context/clientManagementContext';
import { DashboardClientsProvider } from 'context/dashboardClientsContext';
import { DashboardConsumptionProvider } from 'context/dashboardConsumptionContext';
import { DashboardFilterProvider } from 'context/dashboardFilterContext';

import RoundedLoader from 'components/Loader/RoundedLoader';
import { ToastsContainer } from 'components/Toast/ToastContainer';
import DashboardNavLayout from 'layouts/dashboardNavLayout';
import DashboardLayout from 'layouts/dashboardLayout';
import DashboardClients from 'pages/dashboard/DashboardClients';
import DashboardConsumption from 'pages/dashboard/DashboardConsumption';
import DashboardEfficiency from 'pages/dashboard/DashboardEfficiency';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { ClientServiceConsumptionProvider } from 'context/clientServiceConsumptionFilterContext';
import { BlockListProvider } from 'context/blockListContext';
import { ClientPluginLicensesProvider } from 'context/clientPluginLicensesContext';
import { ClientPluginHistoryCallsProvider } from 'context/clientPluginHistoryCallsContext';

const Login = React.lazy(() => import('pages/login/login'));
const ClientManagement = React.lazy(() => import('pages/clientManagement/clientManagement'));
const SpamList = React.lazy(() => import('pages/spamList/spamList'));
const ClientLayout = React.lazy(() => import('layouts/clientLayout'));
const ClientAccount = React.lazy(() => import('pages/client/clientAccount'));
const ClientInvoices = React.lazy(() => import('pages/client/clientInvoices'));
const AddInvoice = React.lazy(() => import('pages/client/addInvoice'));
const ClientServicesLayout = React.lazy(() => import('layouts/clientServicesLayout'));
const ClientPlugin = React.lazy(() => import('pages/client/clientPlugin'));

// We lazy load each component and display a loader while it's rendering

const Routes = () => {
    return (
        <Compose providers={[TitleProvider, LoadingProvider, ModalProvider, CookiesProvider, ToastsProvider]}>
            <Router history={history}>
                <ScrollToTop />
                <Suspense
                    fallback={
                        <RoundedLoader
                            isLoading={true}
                            style={{
                                margin: 'auto',
                                width: '50px',
                                height: '50px',
                            }}
                        />
                    }
                >
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute exact path="/" render={() => <Redirect to="/dashboard" />} />
                        <PrivateRoute exact path="/dashboard" render={() => <Redirect to="/dashboard/clients" />} />
                        <PrivateRoute
                            exact
                            path="/dashboard/clients"
                            component={() => (
                                <Compose providers={[DashboardNavLayout, DashboardLayout]}>
                                    <DashboardClients />
                                </Compose>
                            )}
                            providers={[DashboardClientsProvider, DashboardFilterProvider]}
                        />
                        <PrivateRoute
                            exact
                            path="/dashboard/consumption"
                            component={() => (
                                <Compose providers={[DashboardNavLayout, DashboardLayout]}>
                                    <DashboardConsumption />
                                </Compose>
                            )}
                            providers={[DashboardConsumptionProvider, DashboardFilterProvider]}
                        />
                        <PrivateRoute
                            exact
                            path="/dashboard/efficiency"
                            component={() => (
                                <Compose providers={[DashboardNavLayout]}>
                                    <DashboardEfficiency />
                                </Compose>
                            )}
                        />
                        <PrivateRoute
                            exact
                            path="/client-management"
                            component={ClientManagement}
                            providers={[clientManagementProvider, TableProvider]}
                        />
                        <PrivateRoute
                            exact
                            path="/client-management/:clientId/account"
                            component={() => (
                                <Compose providers={[ClientLayout]}>
                                    <ClientAccount />
                                </Compose>
                            )}
                        />
                        <PrivateRoute
                            exact
                            path="/client-management/:clientId/services"
                            component={() => (
                                <Compose providers={[ClientLayout]}>
                                    <ClientServicesLayout />
                                </Compose>
                            )}
                            providers={[
                                ServicesProvider,
                                ClientServicesProvider,
                                ConsumptionProvider,
                                CalendarProvider,
                                ClientServiceConsumptionProvider,
                            ]}
                        />
                        <PrivateRoute
                            exact
                            path="/client-management/:clientId/plugin"
                            component={() => (
                                <Compose providers={[ClientLayout]}>
                                    <ClientPlugin />
                                </Compose>
                            )}
                            providers={[ClientPluginLicensesProvider, ClientPluginHistoryCallsProvider]}
                        />
                        <PrivateRoute
                            exact
                            path="/client-management/:clientId/invoices"
                            component={() => (
                                <Compose providers={[ClientLayout]}>
                                    <ClientInvoices />
                                </Compose>
                            )}
                            providers={[TableProvider]}
                        />
                        <PrivateRoute exact path="/client-management/:clientId/invoices/add" component={AddInvoice} />
                        <PrivateRoute
                            exact
                            path="/spam-list"
                            component={SpamList}
                            providers={[BlockListProvider, TableProvider]}
                        />
                    </Switch>
                    {/* <Loader /> */}
                    <ToastsContainer />
                </Suspense>
            </Router>
        </Compose>
    );
};

export default Routes;
