import React, { createContext, useReducer, useMemo } from 'react';
import { ContextValue, ProviderProps } from 'interfaces/context';
import { Option } from 'interfaces/option';
import moment from 'moment';

export type PeriodFilter = {
    year: Option;
    month: Option;
};

export enum ActionKind {
    HANDLE_PERIOD_FILTER,
}

export type State = {
    periodFilter: PeriodFilter;
};

export const initialState: State = {
    periodFilter: {
        year: { label: moment().format('YYYY'), value: moment().format('YYYY') },
        month: { label: moment().format('MMMM'), value: moment().format('MM') },
    },
};

type Action = { type: ActionKind.HANDLE_PERIOD_FILTER; payload: PeriodFilter };

const clientServiceConsumptionContext = createContext<ContextValue<State, Action>>({
    state: initialState,
    dispatch: (action) => console.error('Dispatched action outside of a dashboardFilterContext provider', action),
});

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionKind.HANDLE_PERIOD_FILTER: {
            return {
                ...state,
                periodFilter: action.payload,
            };
        }
        default:
            return state;
    }
};

const ClientServiceConsumptionProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextMemoized = useMemo(() => ({ state, dispatch }), [state, dispatch]);
    return <clientServiceConsumptionContext.Provider value={contextMemoized}>{children}</clientServiceConsumptionContext.Provider>;
};
export { clientServiceConsumptionContext, ClientServiceConsumptionProvider };
