/* eslint-disable no-undef */
import { HeatMapSerie } from 'interfaces/client';
import moment from 'moment';
import React, { useRef } from 'react';
import 'assets/scss/chart.scss';
import { useTranslation } from 'react-i18next';
import { COLORS_HEATMAP } from 'utils/formatData';

type CustomHeatmapProps = {
    data: HeatMapSerie[];
    squareSize?: number;
    dataType?: string;
    withLegend?: boolean;
    legendPosition?: 'start' | 'center' | 'end';
    average: number | string;
    withDataTypeLabel?: boolean;
    labelFormatter?: (value: number) => string;
};

const CustomHeatmap: React.FC<CustomHeatmapProps> = ({
    data,
    squareSize = 11,
    dataType = '',
    legendPosition = 'start',
    withLegend = true,
    average,
    withDataTypeLabel = false,
}) => {
    const { t } = useTranslation();
    const tooltipRef = useRef(null);

    // space between squares
    const squareSpace = Math.round(squareSize * 1.3);

    const dayFormat = 'ddd DD-MM-YYYY';

    // TODO WARNING : pas opti car non standard CF https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/layerX
    const showTooltip = (evt: any, text: string) => {
        const tooltip = tooltipRef.current as unknown as HTMLDivElement;
        if (!tooltip) return;

        tooltip!.innerHTML = text;
        tooltip!.style.display = 'block';
        tooltip!.style.left = `${evt.nativeEvent.layerX + 10}px`;
        tooltip!.style.top = `${evt.nativeEvent.layerY + 10}px`;
    };

    const hideTooltip = () => {
        const tooltip = tooltipRef.current as unknown as HTMLDivElement;

        if (!tooltip) return;
        tooltip.style.display = 'none';
    };

    // display a legend with squares
    const drawLegend = () => {
        return Object.values(COLORS_HEATMAP).map((color, index) => {
            if (index === 0) return null;
            return (
                <svg key={`legend-rect-${index}`} width={squareSize} height={squareSize}>
                    <rect
                        width={squareSize}
                        height={squareSize}
                        fill={color}
                        stroke="#000"
                        strokeOpacity={0.1}
                        onMouseEnter={(evt) => showTooltip(evt, t(`chart.legend.heatmap-color-${index}`, { average }))}
                        onMouseLeave={hideTooltip}
                    />
                </svg>
            );
        });
    };

    // display days of the week labels in a column on the left
    const getDaysLabels = () => {
        return moment.weekdaysShort().map((element, index) => (
            <text key={`day-${index}`} dx={-squareSpace} dy={index * squareSpace + 10} className="heatmap-calendar-label">
                {element[0]}
            </text>
        ));
    };

    const drawHeatMap = () => {
        let x = 0;
        const monthsLabels: JSX.Element[] = [];
        const daysLabels: JSX.Element[] = getDaysLabels();

        const heatmap = data.map((currentDayData, index) => {
            const currentDay = currentDayData.date;

            if (!moment(currentDay.format(dayFormat), dayFormat, true).isValid()) {
                console.error(`error with date format : ${currentDay}`);
            }

            const dayNumber = currentDay.day(); // aka "y"

            // if next week has been hit
            if (dayNumber === 0 && index > 0) {
                x += 1;
            }

            // if next month has been hit
            if (currentDay.date() === 1) {
                monthsLabels.push(
                    <text key={`month-${currentDay.format('MMM')}`} x={x * squareSpace} y={-(squareSpace / 2)} className="heatmap-calendar-label">
                        {currentDay.format('MMM')}
                    </text>
                );
            }

            return (
                <rect
                    key={`rect-${x}-${dayNumber}`}
                    width={squareSize}
                    height={squareSize}
                    x={x * squareSpace}
                    y={dayNumber * squareSpace}
                    fill={currentDayData.color}
                    stroke="#000"
                    strokeOpacity={0.1}
                    onMouseEnter={(evt) =>
                        showTooltip(evt, `${currentDayData.date.format(dayFormat)}: ${currentDayData.value} ${withDataTypeLabel ? dataType : ''}`)
                    }
                    onMouseLeave={hideTooltip}
                />
            );
        });

        return [heatmap, monthsLabels, daysLabels];
    };

    return (
        <div className="heatmap-container">
            <div ref={tooltipRef} id="tooltip" style={{ position: 'absolute', display: 'none' }} />
            <svg className="heatmap-svg" style={{ width: '100%', height: 'auto' }}>
                <g transform={`translate(${squareSpace}, 20)`}>{drawHeatMap()}</g>
            </svg>
            {withLegend && (
                <div className="heatmap-legend" style={{ justifyContent: legendPosition }}>
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <span className="mr-1">
                                {t('chart.legend.average')}: {average} {dataType}
                            </span>
                        </div>
                        <div className="mb-3">
                            <span className="mr-1">
                                {t('chart.legend.number')}
                                {dataType}{' '}
                            </span>
                            {drawLegend()}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomHeatmap;
