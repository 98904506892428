import React from 'react';
import { FormikValues, useFormikContext, FormikProps, FormikConfig } from 'formik';
import Button from 'components/Button/Button';

interface AppFormSubmitButtonProps {
    type?: 'submit' | 'button' | undefined;
    requireModification?: boolean;
    isDisabled?: boolean;
    title: string;
    className?: string;
    buttonType?: 'delete' | 'filter' | 'add' | 'edit';
}

/*
 Custom Formik submit button
*/

const AppFormSubmitButton = ({ className = '', requireModification = false, isDisabled = false, ...props }: AppFormSubmitButtonProps) => {
    const { isValid, dirty, values }: FormikProps<FormikValues> & Pick<FormikConfig<FormikValues>, 'validate' | 'validationSchema'> = useFormikContext();

    // Check if form is valid to remove disable prop (aka when all inputs are filled)
    // eslint-disable-next-line no-unused-vars
    const isFormValid = (): boolean => {
        if (isDisabled) return false;

        if (requireModification) {
            return isValid && dirty && Object.values(values).every((x) => !!x)
        }

        return true;
    };

    return <Button type={'submit'} className={className} disabled={!isFormValid()} {...props} />;
};

export default AppFormSubmitButton;
