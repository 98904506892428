import React, { useContext } from 'react';
import { toastContext, ActionType } from 'context/toastContext';
import { randomKeyGen } from 'utils/utils';

export const useToast = () => {
    const { state, dispatch } = useContext(toastContext);

    const getStack = () => {
        return state.stack;
    };

    const addToast = (content: React.FC) => {
        const id = randomKeyGen();
        dispatch({ type: ActionType.ADD_TOAST, payload: { id, content } });
        return id;
    };

    const updateToast = (id: string, content: React.FC) => {
        dispatch({ type: ActionType.UPDATE_TOAST, payload: { id, content } });
    };

    const removeToast = (id: string) => {
        dispatch({ type: ActionType.REMOVE_TOAST, payload: { id } });
    };

    const clearAllToast = () => {
        dispatch({ type: ActionType.CLEAR_ALL });
    };

    return {
        getStack,
        addToast,
        updateToast,
        removeToast,
        clearAllToast,
    };
};
