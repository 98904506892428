import { useRef, useEffect } from 'react';
import axios from 'axios';

/*
 Hook to generate / cancel tokens for each api call
*/

const useCancelToken = () => {
    const CancelToken = axios.CancelToken;
    const isCancel = axios.isCancel;

    const axiosSource = useRef(CancelToken.source());

    const newCancelToken = () => {
        axiosSource.current = CancelToken.source();
        return axiosSource.current.token;
    };

    useEffect(
        () => () => {
            if (axiosSource.current) axiosSource.current.cancel();
        },
        []
    );

    return { newCancelToken, isCancel };
};

export default useCancelToken;
