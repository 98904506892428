/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { PieChartSerie } from 'interfaces/client';
import { ResponsiveContainer, PieChart, Cell, Pie, PieLabel, Legend, Tooltip, Customized } from 'recharts';
import { DataKey } from 'recharts/types/util/types';
import { useTranslation } from 'react-i18next';
import { convertSize } from 'utils/utils';
import 'assets/scss/chart.scss';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

// Adding a custom Props for Pie because PieProps from lib throws an error
type CustomPieProps = {
    dataKey?: DataKey<any>;
    stroke?: string;
    cx?: string | number;
    cy?: string | number;
    labelLine?: boolean;
    label?: boolean | PieLabel<any>;
    textColor?: string;
    cellColor?: string;
    animationDuration?: number;
    innerRadius?: number | string;
    outerRadius?: number | string;
    paddingAngle?: number;
    isAnimationActive?: boolean;
};

// Adding a custom Props for Legend because LegendProps from lib throws an error
type CustomLegendProps = {
    align?: 'left' | 'center' | 'right';
    verticalAlign?: 'top' | 'middle' | 'bottom';
    iconType?: 'line' | 'plainline' | 'square' | 'rect' | 'circle' | 'cross' | 'diamond' | 'star' | 'triangle' | 'wye';
    iconSize?: number;
    margin?: { top?: number; left?: number; right?: number; bottom?: number };
    wrapperStyle?: React.CSSProperties;
    layout?: 'vertical' | 'horizontal';
    content?: React.FC;
    isAnimationActive?: boolean;
};

export interface MandatoryCustomPieChartProps {
    data: PieChartSerie[];
}

export interface OptionalCustomPieChartProps {
    pieChartProps?: CategoricalChartProps;
    pieProps?: Omit<CustomPieProps, 'label'>;
    legendProps?: Omit<CustomLegendProps, 'content'>;
    withPercentageLabel?: boolean; // display a label as a percentage on each section if true OR at the line extremity if piePros.labelLine is true
    withPercentageLegend?: boolean; // display a legend with percentage for each section if true
    withTooltip?: boolean; // display a tooltip & highlight section if true
    withActiveText?: boolean;
    textSubtitle?: string;
}

type CustomLabel = {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    textColor?: string;
};

const RADIAN = Math.PI / 180;

// Render a custom label with percentages in the cell
const RenderPercentageLabelCell: React.FC<CustomLabel> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, textColor = 'white' }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} className="piechart-text" fill={textColor} textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const RenderPercentageLabelLine: React.FC<CustomLabel> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} className="piechart-text" fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

// Render a custom legend with percentages
// Difficult to type as its props are calcultated internally by Recharts depending on the Chart type...
const RenderPercentageLegend: React.FC<any> = (props) => {
    const { t } = useTranslation();
    const { payload, activeIndex, height } = props;

    return (
        <ul style={{ height: height }} className="percentage-container">
            {payload?.map((entry: any, index: number) => {
                const percentStyle = activeIndex === index ? { color: entry.payload.cellColor } : { color: 'black' };

                return (
                    <li className="percentage-label" key={`item-${index}`}>
                        <div className="percentage-name-container">
                            <span className="percentage-icon" style={{ backgroundColor: entry.payload.cellColor }} />
                            <span className="percentage-name">{t(`chart.legend.${entry.value}`)}</span>
                        </div>
                        <span style={percentStyle}>{`${(entry.payload.percent * 100).toFixed(0)}%`}</span>
                    </li>
                );
            })}
        </ul>
    );
};

type RenderCenteredTextProps = {
    activeIndex: number;
    subtitle?: string;
    [x: string]: any;
};

// Draws a text with current item hovered value in the center of the first Pie in PieChart
const RenderCenteredText: React.FC<RenderCenteredTextProps> = (props) => {
    const { formattedGraphicalItems, activeItem, activeIndex, subtitle } = props;

    // Find x & y middle coordinates of the first Pie
    const cx = formattedGraphicalItems[0]?.props?.cx;
    const cy = formattedGraphicalItems[0]?.props?.cy;

    return (
        <>
            {activeIndex >= 0 ? (
                <g>
                    <text x={cx} y={cy} dy={0} textAnchor="middle" fill={activeItem?.cellColor} fontWeight="bold" fontSize="20">
                        {subtitle === 'Consumption' ? convertSize(activeItem?.value) : activeItem?.value}
                    </text>
                    <text x={cx} y={cy} dy={18} textAnchor="middle" fill="grey" fontSize="11">
                        {subtitle}
                    </text>
                </g>
            ) : undefined}
        </>
    );
};

// Documentation : https://recharts.org/en-US/api
const CustomPieChart: React.FC<MandatoryCustomPieChartProps & OptionalCustomPieChartProps> = (props) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const onCellEnter = useCallback(
        (index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const onPieLeave = () => {
        setActiveIndex(-1);
    };

    const handleCellEnter = (index: number) => {
        if (!props.withActiveText) return;
        onCellEnter(index);
    };

    const pieChartProps = {
        margin: { top: 5, right: 5, bottom: 5, left: 40 },
        ...props.pieChartProps,
    };

    const pieProps: CustomPieProps = {
        cx: '50%',
        cy: '50%',
        stroke: 'none',
        dataKey: 'value',
        animationDuration: 1000,
        isAnimationActive: false,
        labelLine: false,
        label: props.withPercentageLabel ? (props.pieProps?.labelLine ? RenderPercentageLabelLine : RenderPercentageLabelCell) : false,
        paddingAngle: 0,
        ...props.pieProps,
    };

    const legendProps: CustomLegendProps = {
        isAnimationActive: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        iconType: 'square',
        iconSize: 14,
        wrapperStyle: { right: '10%' },
        ...props.legendProps,
    };

    return (
        <ResponsiveContainer width="99%" height="99%">
            <PieChart {...pieChartProps}>
                <Pie data={props.data} dataKey="value" activeIndex={activeIndex} onMouseLeave={onPieLeave} {...pieProps}>
                    {props.data.map((cell, key) => (
                        <Cell
                            key={`cell-${key}`}
                            fill={cell.cellColor}
                            fillOpacity={activeIndex === key || activeIndex === -1 ? '1' : '0.4'}
                            onMouseEnter={() => handleCellEnter(key)}
                        />
                    ))}
                </Pie>
                {props.withTooltip && <Tooltip formatter={(value: string, name: string) => [value, t(`chart.tooltip.${name}`)]} />}
                <Legend
                    formatter={(name: string) => <span className="w-100">{t(`chart.legend.${name}`)}</span>}
                    content={
                        props.withPercentageLegend ? (
                            <RenderPercentageLegend activeIndex={activeIndex} height={(props.pieProps?.outerRadius as number) * 2} />
                        ) : undefined
                    }
                    {...legendProps}
                />
                {props.withActiveText && <Customized component={<RenderCenteredText activeIndex={activeIndex} subtitle={props.textSubtitle} />} />}
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPieChart;
