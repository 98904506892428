import { useContext } from 'react';
import { ActionKind, dashboardFilterContext, PeriodFilter } from 'context/dashboardFilterContext';
import { FormikValues } from 'formik';
import { Option } from 'interfaces/option';

/*
 Hook using react context "dashboardFilterContext" to manipulate filters in Dashboard page
*/

type ReturnType = {
    periodFilter: PeriodFilter;
    heatmapFilter: Option;
    startingDate: string;
    endingDate: string;
    service: string;
    submit: boolean;
    updatePeriodFilter: (newOption: Option, dataKey: 'year' | 'month') => void;
    updateHeatmapFilter: (newOption: Option) => void;
    updateStartingDate: (startingDate: string) => void;
    updateEndingDate: (endingDate: string) => void;
    updateService: (service: string) => void;
    updateSubmit: (submit: boolean) => void;
    submitForm: (values: FormikValues) => void;
};

const useDashboardClients = (): ReturnType => {
    const { state, dispatch } = useContext(dashboardFilterContext);

    /* PRIVATE FUNCTIONS */

    const handlePeriodFilter = (payload: PeriodFilter) => {
        dispatch({ type: ActionKind.HANDLE_PERIOD_FILTER, payload });
    };

    const handleHeatmapFilter = (payload: Option) => {
        dispatch({ type: ActionKind.HANDLE_HEATMAP_FILTER, payload });
    };

    const handleStartingDate = (payload: string) => {
        dispatch({ type: ActionKind.HANDLE_STARTING_DATE, payload });
    };

    const handleEndingDate = (payload: string) => {
        dispatch({ type: ActionKind.HANDLE_ENDING_DATE, payload });
    };

    const handleService = (payload: string) => {
        dispatch({ type: ActionKind.HANDLE_SERVICE, payload });
    };

    const handleSubmit = (payload: boolean) => {
        dispatch({ type: ActionKind.HANDLE_SUBMIT, payload });
    };

    /* PUBLIC FUNCTIONS */

    const updatePeriodFilter = (newOption: Option, dataKey: 'year' | 'month') => {
        // update new filter
        const newCreationFilter = { ...state.periodFilter, [dataKey]: newOption };

        handlePeriodFilter(newCreationFilter);
    };

    const updateHeatmapFilter = (newOption: Option) => {
        handleHeatmapFilter(newOption);
    };

    const updateStartingDate = (startingDate: string) => {
        handleStartingDate(startingDate);
    };

    const updateEndingDate = (endingDate: string) => {
        handleEndingDate(endingDate);
    };

    const updateService = (service: string) => {
        handleService(service);
    };

    const updateSubmit = (submit: boolean) => {
        handleSubmit(submit);
    };

    const submitForm = (values: FormikValues) => {
        handleStartingDate(values.startingDate);
        handleEndingDate(values.endingDate);
        handleService(values.service);
        handleSubmit(true);
    };

    return {
        periodFilter: state.periodFilter,
        heatmapFilter: state.heatmapFilter,
        startingDate: state.startingDate,
        endingDate: state.endingDate,
        service: state.service,
        submit: state.submit,
        updatePeriodFilter,
        updateHeatmapFilter,
        updateStartingDate,
        updateEndingDate,
        updateService,
        updateSubmit,
        submitForm
    };
};

export default useDashboardClients;
