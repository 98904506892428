import React, { CSSProperties } from 'react';
import 'assets/scss/table.scss';

type ColumnDefinitionType = {
    key: string;
    header?: string;
    type?: string;
    style?: CSSProperties;
    align?: 'center' | 'left' | 'right' | 'justify' | 'char';
}

type RowDefinitionType = {
    [key: string]: {
        type: string;
        value: string;
    };
}

type TableProps = {
    stickyHead?: boolean
    stickyTop?: string
    data: {
        head: ColumnDefinitionType[];
        body: RowDefinitionType[]
    }
};

// TODO Improve table integration and refacto components/Table to be more flexible
const CountryTable: React.FC<TableProps> = ({
    data,
    stickyHead = true,
    stickyTop = 0
}) => {
    const { head, body } = data;
    const headStyle: CSSProperties = stickyHead ? { position: 'sticky', top: stickyTop } : {};

    const columns = head?.map((column: ColumnDefinitionType, index: number) => {
        const style = column?.style ?? {
            fontWeight: '600',
            maxWidth: '20%'
        };

        return (
            <th
                align={column?.align || 'left'}
                key={`headCell-${index}`}
                style={{ ...style, width: '100%' }}
                className='px-3'
            >
                {column.header}
            </th>
        );
    });

    const rows = body.map((row: any, index: number) => {
        return (
            <tr key={`row-${index}`}>
                {
                    Object.keys(row).map((data, index2) => {
                        const cell = row[data];

                        return (
                            <td key={`cell-${index2}`} className='px-3' align={cell.align ?? 'left'}>
                                {cell.value ?? ''}
                            </td>
                        );
                    })
                }
            </tr>
        );
    });

    return (
        <table className='table'>
            <thead id='table-head' style={headStyle}>
                <tr>{columns}</tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

export default CountryTable;
