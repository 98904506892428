import React, { createContext, ReactNode, useReducer } from 'react';

export type Toast = {
    content: React.FC,
    id: string,
    process?(): Promise<any>
};

export interface ToastNotificationState {
    stack: Toast[];
};

const initialState: ToastNotificationState = {
    stack: []
};

type ToastContext = {
    state: ToastNotificationState,
    dispatch: React.Dispatch<ToastAction>;
};

export enum ActionType {
    // eslint-disable-next-line no-unused-vars
    ADD_TOAST,
    // eslint-disable-next-line no-unused-vars
    REMOVE_TOAST,
    // eslint-disable-next-line no-unused-vars
    UPDATE_TOAST,
    // eslint-disable-next-line no-unused-vars
    CLEAR_ALL,
};

type ToastAction =
    | { type: ActionType.ADD_TOAST, payload: { id: string, content: ReactNode } }
    | { type: ActionType.REMOVE_TOAST, payload: { id: string } }
    | { type: ActionType.UPDATE_TOAST, payload: { id: string, content: ReactNode } }
    | { type: ActionType.CLEAR_ALL }

const toastContext = createContext<ToastContext>({
    state: initialState,
    dispatch: () => null
});

const reducer = (state: ToastNotificationState, action: ToastAction): ToastNotificationState => {
    const { type, payload } = action as any;
    switch (type) {
        case ActionType.ADD_TOAST:
            return {
                ...state,
                stack: [...state.stack, { id: payload.id, content: payload.content }]
            };
        case ActionType.REMOVE_TOAST:
            return {
                ...state,
                stack: state.stack.filter(toast => toast.id !== payload.id)
            };
        case ActionType.UPDATE_TOAST:
            return {
                ...state,
                stack: state.stack.map(toast => toast.id === payload.id ? payload : toast)
            };
        case ActionType.CLEAR_ALL:
            return {
                ...state,
                stack: []
            };
        default: return state;
    }
};

export interface IToastProvider {
    timeoutBeforeDestroy?: number
};

const ToastsProvider: React.FC<IToastProvider> = ({ children }) => {
    const initState = () => ({
        ...initialState
    });

    const [state, dispatch] = useReducer(reducer, initState());
    return <toastContext.Provider value={{ state, dispatch }}>{children}</toastContext.Provider>;
};

export {
    ToastsProvider,
    toastContext
};
