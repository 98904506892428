import React, { createContext, ReactNode, useState } from 'react';

/*
 Context & Provider for loading management over the app
*/

type LoadingContext = {
    isLoading: boolean;
    setLoading: (loading: boolean) => void
};

const initialState: LoadingContext = {
    isLoading: false,
    setLoading: () => null
};

const loadingContext = createContext(initialState);

const LoadingProvider = ({ children }: { children?: ReactNode }) => {
    const [state, setState] = useState(initialState);

    const setLoading = (loading: boolean) => {
        setState({
            ...state,
            isLoading: loading
        });
    };

    return (
        <loadingContext.Provider value={{ ...state, setLoading }}>{children}</loadingContext.Provider>
    );
};

export { loadingContext, LoadingProvider };
