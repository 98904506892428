import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { titleContext } from 'components/Title/context/titleContext';
import history from 'routing/history';
import { ReactComponent as ArrowBackSvg } from 'assets/icons/icon-back-arrow.svg';
import 'assets/scss/title.scss';

const Title = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { goBack, content } = useContext(titleContext);

    const displayHeaderTitle = () => {
        return t(`header.${pathname.split('/').pop()}`);
    };

    const goBackHistory = () => {
        history.push(goBack as string);
    };

    return (
        <div className='d-flex align-items-center'>
            {
                goBack &&
                     <div className='mr-2'>
                         <ArrowBackSvg
                             className='back-svg'
                             onClick={goBackHistory}
                         />
                     </div>
            }
            <h1 className='mb-0'>{displayHeaderTitle()}</h1>
            <h2 className='subsection-number mb-0 px-2 pt-2'>{content}</h2>
        </div>
    );
};

export default Title;
