import React, { useState } from 'react';
import 'assets/scss/dashboard.scss';
import { Card } from 'components/Cards/Card';
import Button from 'components/Button/Button';
import { ReactComponent as VisibleSvg } from '../../assets/icons/icon-show.svg';
import { ReactComponent as CopiedSvg } from '../../assets/icons/icon-copy.svg';

import { sleep } from 'utils/utils';
import { useToast } from 'hooks/useToast';
import { useTranslation } from 'react-i18next';

const PASSWORD = 'LYlSMbLSAlifCfvaPofr';
const LOGIN = 'elastic';
const generalUrl = 'https://elastic.wassa-prod.com/login?next=%2Fgoto%2Fd50f6190-329e-11ed-a9b3-93d7474b3606';
const managerUrl =
    'https://elastic.wassa-prod.com/app/dashboards#/view/33738f10-cb44-11ee-a8a5-c1647a1c071c?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))';
const DashboardEfficiency = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { addToast, removeToast } = useToast();
    const { t } = useTranslation();

    const Toast = (data: string) => (
        <>
            <CopiedSvg fill="grey" height={100} />
            <span className="pl-1">{t('dashboard.service-efficiency.copied-success-toast', { data: data })}</span>
        </>
    );

    const handleClickCopy = async (text: string, dataType: 'Login' | 'Password') => {
        const id = addToast(() => Toast(dataType));
        try {
            await navigator.clipboard.writeText(text);
            await sleep(1000);
            removeToast(id);
        } catch (e) {
            removeToast(id);
            alert('Unable to copy (are you in a https environment ?)');
        }
    };

    const handleClickVisible = (v: boolean) => {
        setIsVisible(v);
    };

    const displayPassword = () => {
        if (isVisible) {
            return PASSWORD;
        }
        return Array.from(new Array(PASSWORD.length)).map((_) => '*');
    };

    return (
        <Card classNames="dashboard-service-efficiency" cardType="shadowed" cardRadius="rounded">
            <h4 className="dashboard-section-title">{t('dashboard.service-efficiency.section-title')}</h4>
            <Card cardType="shadowed" cardRadius="squared">
                <h5 className="dashboard-content-title">{t('dashboard.service-efficiency.information-title')}</h5>
                <div className="dashboard-service-efficiency__group mb-3">
                    <label>{t('dashboard.service-efficiency.visit-website-label')}</label>
                    <div className="d-flex">
                        <a
                            className="dashboard-service-efficiency__button my-3 mx-4"
                            href={generalUrl}
                            target="_blank"
                            title="elastic search link"
                        >
                            {t('dashboard.service-efficiency.visit-website-button-general')}
                        </a>
                        <span></span>
                        <a
                            className="dashboard-service-efficiency__button my-3"
                            href={managerUrl}
                            target="_blank"
                            title="elastic search link"
                        >
                            {t('dashboard.service-efficiency.visit-website-button-mngr')}
                        </a>
                    </div>
                </div>
                <div className="dashboard-service-efficiency__group mb-3">
                    <label>{t('dashboard.service-efficiency.login-label')}</label>
                    <span>{LOGIN}</span>
                    <Button
                        onClick={() => handleClickCopy(LOGIN, 'Login')}
                        className="btn-cta"
                        title={t('dashboard.service-efficiency.copy-login-button')}
                    ></Button>
                </div>
                <div className="dashboard-service-efficiency__group mb-3">
                    <label>{t('dashboard.service-efficiency.password-label')}</label>
                    <span className="d-flex justify-content-between align-items-center">
                        <span>{displayPassword()}</span>
                        <span className="mr-1">
                            {isVisible ? (
                                <VisibleSvg
                                    onClick={() => handleClickVisible(false)}
                                    style={{ cursor: 'pointer' }}
                                    className="show-button"
                                    height={'30px'}
                                    width={'20px'}
                                />
                            ) : (
                                <VisibleSvg
                                    onClick={() => handleClickVisible(true)}
                                    style={{ cursor: 'pointer' }}
                                    className="show-button"
                                    height={'30px'}
                                    width={'20px'}
                                />
                            )}
                        </span>
                    </span>
                    <Button
                        onClick={() => handleClickCopy(PASSWORD, 'Password')}
                        className="btn-cta btn-with-tooltip"
                        title={t('dashboard.service-efficiency.copy-password-button')}
                    ></Button>
                </div>
            </Card>
        </Card>
    );
};

export default DashboardEfficiency;
