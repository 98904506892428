import React, { useEffect, useState } from 'react';
import { ClientAggregationConsumption, PieChartSerie } from 'interfaces/client';
import { useAxios } from 'services/requestService';
import { formatClientConsumptionAggregates, formatServiceRepartitionPieChart } from 'utils/formatData';
import { SelectServiceOption } from 'hooks/useDashboardConsumptionFilter';

type GetConsumptionFilters = {
    granularity: 'day' | 'month';
};

export type FiltersState = {
    services?: SelectServiceOption;
    dateStart?: string;
    dateEnd?: string;
};

export type FilterInput = {
    service?: string;
    dateStart?: string;
    dateEnd?: string;
};

export const useDashboardClientConsumption = (params: GetConsumptionFilters & FilterInput) => {
    const [data, setData] = useState<ClientAggregationConsumption | null>(null);
    const { getData2, error, individualLoader } = useAxios();

    const getConsumption = async (filters: GetConsumptionFilters & FilterInput) => {
        const { data: consumption } = await getData2<ClientAggregationConsumption>('admin/clients/consumption', filters);
        setData(consumption);
    };

    useEffect(() => {
        getConsumption(params);
    }, []);

    const getApiCallsAndDataConsumption = () => {
        if (data) {
            return formatClientConsumptionAggregates(data);
        } else return null;
    };

    const getPieChartSerie = (): { apiCalls: PieChartSerie[]; consumption: PieChartSerie[] } => {
        const formattedData = getApiCallsAndDataConsumption();
        if (formattedData) {
            return {
                apiCalls: [
                    { name: 'images', value: formattedData?.apiCallAggregates.total.totalImage, cellColor: '#f5c60a', textColor: '#f5c60a' },
                    { name: 'videos', value: formattedData?.apiCallAggregates.total.totalVideo, cellColor: '#0acef5', textColor: '#0acef5' },
                ],
                consumption: [
                    { name: 'images', value: formattedData?.consumptionAggregates.total.totalImage, cellColor: '#f5c60a', textColor: '#f5c60a' },
                    { name: 'videos', value: formattedData?.consumptionAggregates.total.totalVideo, cellColor: '#0acef5', textColor: '#0acef5' },
                ],
            };
        } else return { apiCalls: [], consumption: [] };
    };

    const submitGetConsumption = async (filters: GetConsumptionFilters & FilterInput) => {
        await getConsumption(filters);
    };

    const getServiceRepartitionPieChartSerie = () => {
        if (data?.prevAggregates) {
            const formattedData = formatServiceRepartitionPieChart(data?.prevAggregates, data?.dailyAggregates);
            return {
                apiCalls: Object.keys(formattedData).map((key) => ({
                    name: key,
                    value: (formattedData as any)[key].apiCalls,
                    cellColor: (formattedData as any)[key].color,
                    textColor: (formattedData as any)[key].color,
                })),
                consumption: Object.keys(formattedData).map((key) => ({
                    name: key,
                    value: (formattedData as any)[key].consumption,
                    cellColor: (formattedData as any)[key].color,
                    textColor: (formattedData as any)[key].color,
                })),
            };
        }
    };

    return {
        data,
        error,
        individualLoader,
        getApiCallsAndDataConsumption,
        getPieChartSerie,
        submitGetConsumption,
        getServiceRepartitionPieChartSerie,
    };
};
