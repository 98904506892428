import { ReactNode, useContext } from 'react';
import { SweetAlertOptionalProps } from 'react-bootstrap-sweetalert/dist/types';
import { randomKeyGen } from 'utils/utils';
import { modalContext } from 'components/Modal/context/modalContext';

/*
 Hook using react context "modalContext" to manipulate modal data
 It keeps alerts in the state, and remove them when the onConfirm or onCancel callbacks are invoked.
*/

type CustomProps = {
    id?: string,
    isLoading?: boolean,
    content?: ReactNode|string,
    title?: ReactNode|string;
    onConfirm?: (response?: any) => any;
}

const useModal = () => {
    const { state, dispatch } = useContext(modalContext);

    const addAlert = (alert: SweetAlertOptionalProps & CustomProps) => {
        dispatch({ type: 'ADD_ALERT', alert });
    };

    const removeAlert = (id: string) => {
        dispatch({ type: 'REMOVE_ALERT', id });
    };

    const clearAlert = () => {
        dispatch({ type: 'CLEAR_ALERT' });
    };

    const closeAlert = (id?: string) => {
        if (id) { removeAlert(id); }
    };

    const showAlert = (alertProps: SweetAlertOptionalProps & CustomProps) => {
        if (!alertProps.id) {
            alertProps.id = randomKeyGen();
        }
        if (!alertProps.title) {
            alertProps.title = '';
        }

        // auto-close on confirm
        if (alertProps.onConfirm) {
            const onConfirm = alertProps.onConfirm;
            alertProps.onConfirm = (...args: []) => {
                onConfirm(...args);
                closeAlert(alertProps.id);
            };
        } else {
            alertProps.onConfirm = () => closeAlert(alertProps.id);
        }

        // auto-close on cancel
        if (alertProps.onCancel) {
            const onCancel = alertProps.onCancel;
            alertProps.onCancel = (...args: []) => {
                onCancel(...args);
                closeAlert(alertProps.id);
            };
        } else {
            alertProps.onCancel = () => closeAlert(alertProps.id);
        }
        addAlert(alertProps);
    };

    return {
        alerts: state.alerts,
        visibleAlert: state.alerts.length > 0 ? state.alerts[0] : null,
        showAlert,
        addAlert,
        closeAlert,
        clearAlert
    };
};

export default useModal;
