import React from "react";
import Select, { Props, StylesConfig } from "react-select";
import { Option as OptionType } from "interfaces/option";

interface ISelect {
	options: Array<OptionType> | undefined;
	placeholder?: string;
	isDisabled?: boolean;
}

export const customStyle: StylesConfig = {
	control: (provided, state) => ({
		...provided,
		minWidth: "165px",
		border: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA",
		boxShadow: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA",
		fontWeight: 200,
		fontSize: "14px",
		textTransform: "capitalize",
		"&:hover": {
			border: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA",
		},
	}),
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? "white" : "#6b7897",
		fontWeight: 200,
		fontSize: "14px",
		textTransform: "capitalize",
		backgroundColor: state.isSelected
			? "rgba(107, 120, 151, 0.8)"
			: state.isFocused
			? "rgba(107, 120, 151, 0.2)"
			: "none",
		":active": {
			...provided[":active"],
			backgroundColor: state.isSelected
				? "rgba(107, 120, 151, 0.8)"
				: "none",
		},
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: "14px",
		fontWeight: 200,
		textTransform: "capitalize",
	}),
	placeholder: (provided, state) => ({
		...provided,
		fontSize: "14px",
		fontWeight: 200,
		textTransform: "capitalize",
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 10,
	}),
	loadingIndicator: (provided) => ({
		...provided,
		transform: "scale(0.5)",
	}),
};

const CustomSelect: React.FC<ISelect & Props> = ({
	onChange,
	options,
	value,
	placeholder,
	isDisabled = false,
	styles,
	...props
}) => (
	<Select
		styles={{ ...customStyle }}
		options={options}
		onChange={onChange}
		value={value}
		placeholder={placeholder}
		isDisabled={isDisabled}
		{...props}
	/>
);

export default CustomSelect;
