
import React, { createContext, ReactNode, useState } from 'react';

/*
 Context & Provider for back arrow in title + subsection beside title in "Title.tsx"
*/

interface Title {
    goBack: string | null;
    content: string;
};

interface Update {
    updateTitle: (
        goBack: string | null,
        content: string,
    ) => void
}

const initialState: Title & Update = {
    goBack: null,
    content: '',
    updateTitle: () => null
};

const titleContext = createContext(initialState);

const TitleProvider = ({ children }: { children?: ReactNode }) => {
    const [state, setState] = useState(initialState);

    const updateTitle = (
        goBack: string | null,
        content: string
    ) => {
        if (goBack !== state.goBack || content !== state.content) {
            setState({
                ...state,
                goBack: goBack,
                content: content
            });
        }
    };

    return (
        <titleContext.Provider value={{ ...state, updateTitle }}>{children}</titleContext.Provider>
    );
};

export { titleContext, TitleProvider };
