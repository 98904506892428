import DashboardFilter from 'pages/dashboard/components/DashboardFilter';
import React from 'react';
import 'assets/scss/dashboard.scss';

const DashboardLayout: React.FC = ({ children }) => {
    return (
        <div className='dashboard-layout'>
            <DashboardFilter />
            {children}
        </div>
    );
};

export default DashboardLayout;
