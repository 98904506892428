/* eslint-disable space-before-function-paren */
import { useContext, useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import Cookies from "js-cookie";
import useCancelToken from "hooks/useCancelToken";
import history from "routing/history";
import { authHeader } from "services/API";
import { loadingContext } from "components/Loader/context/loaderContext";

interface IResponse {
	error: boolean;
	data: any;
}

interface IResponseWithGeneric<T> {
	error: boolean;
	data: T | null;
}

export const logout = () => {
	Cookies.remove("Auth", { path: "/" });
	history.push("/login");
};

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response } = error;
		if (
			response?.status === 401 &&
			!response?.request?.responseURL?.includes("login-ldap")
		) {
			logout();
			document.location.reload();
		}
		return Promise.reject(error);
	}
);

// Hook to use axios
export const useAxios = () => {
	const loading = useContext(loadingContext);
	const [individualLoader, setIndividualLoader] = useState(false); // state to handle a loader without using the global loader provided by loading context
	const [error, setError] = useState<boolean>(false);
	const [response, setResponse] = useState<AxiosResponse | null>(null);

	const { newCancelToken, isCancel } = useCancelToken();

	async function getData2<ReponseType>(
		route: string,
		params?: any,
		isPrivate: boolean = true
	): Promise<IResponseWithGeneric<ReponseType>> {
		const requestOptions: AxiosRequestConfig = {
			headers: isPrivate ? authHeader() : {},
			params: params,
			cancelToken: newCancelToken(),
		};

		try {
			loading.setLoading(true);
			setIndividualLoader(true);
			const { data } = await axios.get(`/${route}`, requestOptions);
			loading.setLoading(false);
			setIndividualLoader(false);
			setResponse(data);
			return {
				data,
				error,
			};
		} catch (e: any) {
			if (isCancel(e)) {
				console.log("Request canceled", e.message);
			}
			setIndividualLoader(false);
			loading.setLoading(false);
			setError(true);
			return {
				data: null,
				error: e,
			};
		}
	}

	async function getData(
		route: string,
		params: any,
		isPrivate: boolean = true,
		responseType: ResponseType = "json"
	): Promise<IResponse> {
		const requestOptions: AxiosRequestConfig = {
			headers: isPrivate ? authHeader() : {},
			params: params,
			cancelToken: newCancelToken(),
			responseType,
		};

		try {
			loading.setLoading(true);
			const { data } = await axios.get(`/${route}`, requestOptions);
			setResponse(data);
			return {
				data,
				error,
			};
		} catch (e: any) {
			if (isCancel(e)) {
				console.log("Request canceled", e.message);
			}
			setError(true);
			return {
				data: null,
				error: e,
			};
		} finally {
			loading.setLoading(false);
		}
	}

	const postData = async (
		route: string,
		params?: any,
		isPrivate: boolean = true,
		customHeaders: any = {}
	): Promise<IResponse> => {
		const requestOptions: AxiosRequestConfig = {
			headers: isPrivate
				? { ...authHeader(), customHeaders }
				: customHeaders,
			cancelToken: newCancelToken(),
		};

		try {
			loading.setLoading(true);
			const { data } = await axios.post(
				`/${route}`,
				params,
				requestOptions
			);
			setResponse(data);
			return {
				data,
				error,
			};
		} catch (e: any) {
			if (isCancel(e)) {
				console.log("Request canceled", e);
			}
			setError(true);
			throw e;
		} finally {
			loading.setLoading(false);
		}
	};

	const putData = async (
		route: string,
		params?: any,
		isPrivate: boolean = true
	): Promise<IResponse> => {
		const requestOptions: AxiosRequestConfig = {
			headers: isPrivate ? authHeader() : {},
			cancelToken: newCancelToken(),
		};

		try {
			loading.setLoading(true);
			const { data } = await axios.put(
				`/${route}`,
				params,
				requestOptions
			);
			setResponse(data);
			return {
				data,
				error,
			};
		} catch (e: any) {
			if (isCancel(e)) {
				console.log("Request canceled", e.message);
			}
			setError(true);
			throw e;
		} finally {
			loading.setLoading(false);
		}
	};

	const delData = async (
		route: string,
		rest?: AxiosRequestConfig,
		isPrivate: boolean = true
	): Promise<IResponse> => {
		const requestOptions: AxiosRequestConfig = {
			headers: isPrivate ? authHeader() : {},
			cancelToken: newCancelToken(),
			...rest,
		};

		try {
			loading.setLoading(true);
			const { data } = await axios.delete(`/${route}`, requestOptions);
			setError(false);
			setResponse(data);
			return {
				data,
				error,
			};
		} catch (e: any) {
			if (isCancel(e)) {
				console.log("Request canceled", e.message);
			}

			setError(true);
			throw e;
		} finally {
			loading.setLoading(false);
		}
	};

	return {
		error,
		response,
		postData,
		getData2,
		putData,
		delData,
		getData,
		loading,
		individualLoader,
	};
};
