/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { ClientService } from "interfaces/services";
import moment from "moment";
import { License } from "./license";
export interface ClientSupport {
  uuid: string;
  dateStart: string;
  dateEnd: string | null;
  price: number | null;
  support: {
    uuid: string;
    name: string;
    defaultPrice: number;
    startDate: string;
    endDate: string | null;
  };
}

export enum RequestOrigin {
  Services = "services",
  FacesAttributes = "face_attributes",
  FacesRecognitions = "face_recognition",
  Facelytics = "facelytics",
  FacelyticsApp = "facelytics_app",
  Manager = "manager_app",
  Blurit = "blurit",
}

export enum InscriptionSubOrigin {
  API = "api",
  OP = "on premise",
  PLUGIN = "plugin",
}

export enum ClientType {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum ClientCurrency {
  EUR = "EUR",
  USD = "USD",
}

export interface Client {
  BIC_SWIFT: string | null;
  IBAN: string | null;
  address: string | null;
  bankAddress: string | null;
  bankName: string | null;
  city: string | null;
  clientService: ClientService[] | [];
  clientSupport?: ClientSupport[] | [];
  clientCurrency?: ClientCurrency;
  comments: string | null;
  companyId: string | null;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  creditCardExpirationNumber: string | null;
  creditCardNumber: string | null;
  creditCardType: string | null;
  created_at: string | null;
  updated_at: string | null;
  date: { created: string | null; updated: string | null };
  firstName: string | null;
  inscriptionOrigin: RequestOrigin | null;
  inscriptionSubOrigin: InscriptionSubOrigin | null;
  invoicingContact: string | null;
  invoicingEmail: string | null;
  lastName: string | null;
  legalForm: string | null;
  mail: string | null;
  name: string | null;
  phone: string | null;
  postalCode: string | null;
  randomKey: string | null;
  type: ClientType;
  uuid: string;
  vatNumber: string | null;
  CGU: string | null;
  paiementStatus: string | null;
  stripeId: string | null;
  isTrial: boolean;
  licenses: License[];
}

export interface ChartSerie {
  name: string;
}

export interface PieChartSerie extends ChartSerie {
  value: number;
  textColor?: string;
  cellColor?: string;
}

export interface HeatMapSerie {
  date: moment.Moment;
  value: number | string;
  color: string;
}

export interface AccountSerie extends ChartSerie {
  basic: number;
  standard: number;
  premium: number;
  trial: number;
}

export interface ConsumptionSerie extends ChartSerie {
  calls: number;
  consumption: number;
}

export enum Plans {
  BASIC = "BASIC",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
  TRIAL = "TRIAL",
}

export enum ActivedServices {
  SDK = "SDK",
  ON_PREMISE = "ON_PREMISE",
  BLURIT_STUDIO_API = "BLURIT_STUDIO_API",
  BLURIT_PLUGIN = "BLURIT_PLUGIN",
  WIS_OTHERS = "WIS_OTHERS",
}

export type ExtendedPlans = Plans | "ACTIVE";

export type ClientCountElement = {
  type: Plans | "ACTIVE";
  total: string;
};

export type ClientCountDateElement = {
  type: Plans;
  date: string;
  total: string;
};

// /admin/clients/count
export type ClientCount = {
  totalCreated: ClientCountElement[];
  totalCreatedPerPeriod: ClientCountDateElement[];
};

// /history-call/count
export type ConsumptionCount = {
  type: "PICTURE" | "VIDEO";
  totalCount: string;
  size: string;
};

// /history-call/count/service
export type ConsumptionByService = {
  service: string;
  totalCount: string;
  size: string;
};

export type ConsumptionByPeriod = {
  date: string;
  totalCount: string;
  size: string;
};

type AggregationConsumptionKeys =
  | "period"
  | "anonymization_image_api_calls"
  | "anonymization_image_consumption"
  | "anonymization_video_api_calls"
  | "anonymization_video_consumption"
  | "congestion_image_api_calls"
  | "congestion_image_consumption"
  | "fake_detection_image_api_calls"
  | "fake_detection_image_consumption"
  | "anpr_image_api_calls"
  | "anpr_image_consumption"
  | "facelytics_image_api_calls"
  | "facelytics_image_consumption"
  | "identity_create_image_api_calls"
  | "identity_create_image_consumption"
  | "identity_recognition_image_api_calls"
  | "identity_recognition_image_consumption"
  | "identity_search_image_api_calls"
  | "identity_search_image_consumption"
  | "identity_update_image_api_calls"
  | "identity_update_image_consumption"
  | "orientation_image_api_calls"
  | "orientation_image_consumption"
  | "soiling_image_api_calls"
  | "soiling_image_consumption"
  | "total_image_api_calls"
  | "total_image_consumption"
  | "total_video_api_calls"
  | "total_video_consumption"
  | "vehicules_and_pedestrian_image_api_calls"
  | "vehicules_and_pedestrian_image_consumption"
  | "watermark_image_api_calls"
  | "watermark_image_consumption"
  | "watermark_video_api_calls"
  | "watermark_video_consumption";

export type AggregationConsumption = {
  [keys in AggregationConsumptionKeys]: string;
};

export type ClientAggregationConsumption = {
  prevAggregates: AggregationConsumption[];
  dailyAggregates: AggregationConsumption | undefined;
};

export type ConsumptionCell = {
  total_image_api_calls: number;
  total_video_api_calls: number;
  total_image_consumption: number;
  total_video_consumption: number;
  period: string;
};
