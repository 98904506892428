import React from 'react';
import 'assets/scss/avatar.scss';

interface IAvatarProps {
    title: string
}

const Avatar = ({ title }: IAvatarProps) => (
    <div className='avatar d-flex align-items-center justify-content-center'>
        <span className='avatar-content'>{title}</span>
    </div>
);

export default Avatar;
