import React, { createContext, ReactNode, useReducer } from 'react';

type IinitialState = {
    alerts: Array<any>,
};

const initialState = {
    alerts: []
};

const modalContext = createContext<{
    state: IinitialState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as IinitialState,
    dispatch: () => null
});

const reducer = (state: any, action: any) => {
    const { type } = action;

    switch (type) {
        case 'ADD_ALERT':
            return {
                ...state,
                alerts: [...state.alerts, action.alert]
            };
        case 'REMOVE_ALERT':
            return {
                ...state,
                alerts: [...state.alerts].filter(a => a.id !== action.id)
            };
        case 'CLEAR_ALERT':
            return {
                ...state,
                alerts: []
            };
        default:
            return state;
    }
};

const ModalProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <modalContext.Provider value={{ state, dispatch }}>{children}</modalContext.Provider>;
};

export { modalContext, ModalProvider };
