import React from 'react';
import 'assets/scss/toast.scss';
import { useToast } from 'hooks/useToast';

interface IToast {
    id: string,
}

const Toast: React.FC<IToast> = ({
    children
}) => {
    return (
        <div className='wis-toast'>
            {children}
        </div>
    );
};

export const ToastsContainer = () => {
    const {
        getStack
    } = useToast();

    const stack = getStack();
    return (
        <div className='toast-container'>
            {stack.map(toast => (<Toast id={toast.id} key={toast.id}><toast.content key={toast.id} /></Toast>))}
        </div>
    );
};
