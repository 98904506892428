import React, { CSSProperties } from 'react';
import '../../assets/scss/card.scss';

type Props = {
    styles?: CSSProperties;
    classNames?: string;
    cardType: 'bordered' | 'shadowed';
    cardRadius?: 'squared' | 'rounded';
};

export const Card: React.FC<Props> = ({ children, cardType = 'bordered', classNames, styles, cardRadius = 'rounded' }) => {
    return (
        <div className={`wis-card ${classNames ?? ''}`} card-radius={cardRadius} card-type={cardType} style={styles}>
            {children}
        </div>
    );
};
