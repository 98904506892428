/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useMemo } from 'react';
import { ContextValue, ProviderProps } from 'interfaces/context';
import { Option } from 'interfaces/option';
import moment from 'moment';

export type PeriodFilter = {
    year: Option;
    month: Option;
};

export enum ActionKind {
    HANDLE_PERIOD_FILTER,
    HANDLE_HEATMAP_FILTER,
    HANDLE_STARTING_DATE,
    HANDLE_ENDING_DATE,
    HANDLE_SERVICE,
    HANDLE_SUBMIT,
}

export type State = {
    periodFilter: PeriodFilter;
    heatmapFilter: Option;
    startingDate: string;
    endingDate: string;
    service: string;
    submit: boolean;
};

export const initialState: State = {
    periodFilter: {
        year: { label: moment().format('YYYY'), value: moment().format('YYYY') },
        month: { label: moment().format('MMMM'), value: moment().format('MM') },
    },
    heatmapFilter: { label: moment().format('YYYY'), value: moment().format('YYYY') },
    startingDate: '',
    endingDate: '',
    service: 'all',
    submit: true,
};

type Action =
    | { type: ActionKind.HANDLE_PERIOD_FILTER; payload: PeriodFilter }
    | { type: ActionKind.HANDLE_HEATMAP_FILTER; payload: Option }
    | { type: ActionKind.HANDLE_STARTING_DATE; payload: string }
    | { type: ActionKind.HANDLE_ENDING_DATE; payload: string }
    | { type: ActionKind.HANDLE_SERVICE; payload: string }
    | { type: ActionKind.HANDLE_SUBMIT; payload: boolean };

const dashboardFilterContext = createContext<ContextValue<State, Action>>({
    state: initialState,
    dispatch: (action) => console.error('Dispatched action outside of a dashboardFilterContext provider', action),
});

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionKind.HANDLE_PERIOD_FILTER:
            return {
                ...state,
                periodFilter: action.payload,
            };
        case ActionKind.HANDLE_HEATMAP_FILTER:
            return {
                ...state,
                heatmapFilter: action.payload,
            };
        case ActionKind.HANDLE_STARTING_DATE:
            return {
                ...state,
                startingDate: action.payload,
            };
        case ActionKind.HANDLE_ENDING_DATE:
            return {
                ...state,
                endingDate: action.payload,
            };
        case ActionKind.HANDLE_SERVICE:
            return {
                ...state,
                service: action.payload,
            };
        case ActionKind.HANDLE_SUBMIT:
            return {
                ...state,
                submit: action.payload,
            };
        default:
            return state;
    }
};

const DashboardFilterProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <dashboardFilterContext.Provider value={contextValue}>{children}</dashboardFilterContext.Provider>;
};

export { dashboardFilterContext, DashboardFilterProvider };
