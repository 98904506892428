import { useContext, useEffect } from 'react';
import moment from 'moment';
import { useAxios } from 'services/requestService';
import { dashboardClientsContext, ActionKind, Account, ClientCountries } from 'context/dashboardClientsContext';
import useDashboardFilter from 'hooks/useDashboardFilter';
import { AccountSerie, ClientCount, ClientCountDateElement, ClientCountElement } from 'interfaces/client';
import { formatAccounts, formatMonthlyCreatedAccounts, formatYearlyCreatedAccounts } from 'utils/formatData';

/*
 Hook using react context "dashboardClientsContext" to manipulate clients data in Dashboard 1st tab
*/

type ReturnType = {
    accounts: Account;
    yearlyAccounts: AccountSerie[];
    monthlyAccounts: AccountSerie[];
    countriesAccounts: ClientCountries;
    updateAccounts: (accounts: ClientCountElement[], activeAccounts: number) => void;
    updateYearlyAccounts: (accounts: ClientCountDateElement[], year: string) => void;
    updateMonthlyAccounts: (accounts: ClientCountDateElement[], month: string) => void;
    updateCountryAccounts: (countriesAccounts: ClientCountries) => void;
};

const useDashboardClients = (): ReturnType => {
    const { state, dispatch } = useContext(dashboardClientsContext);
    const dashboardFilter = useDashboardFilter();
    const { getData } = useAxios();

    /* PRIVATE FUNCTIONS */

    const handleAccounts = (payload: Account) => {
        dispatch({ type: ActionKind.HANDLE_ACCOUNTS, payload });
    };

    const handleYearlyAccounts = (payload: AccountSerie[]) => {
        dispatch({ type: ActionKind.HANDLE_YEARLY_ACCOUNTS, payload });
    };

    const handleMonthlyAccounts = (payload: AccountSerie[]) => {
        dispatch({ type: ActionKind.HANDLE_MONTHLY_ACCOUNTS, payload });
    };

    const handleCountryAccounts = (payload: ClientCountries) => {
        dispatch({ type: ActionKind.HANDLE_COUNTRIES_ACCOUNTS, payload });
    };

    /* PUBLIC FUNCTIONS */

    const updateAccounts = (accounts: ClientCountElement[]) => {
        const formattedAccounts: Account = formatAccounts(accounts);

        handleAccounts(formattedAccounts);
    };

    const updateYearlyAccounts = (accounts: ClientCountDateElement[], year: string) => {
        const formattedAccounts: AccountSerie[] = formatYearlyCreatedAccounts(accounts, year);

        handleYearlyAccounts(formattedAccounts);
    };

    const updateMonthlyAccounts = (accounts: ClientCountDateElement[], month: string) => {
        const formattedAccounts: AccountSerie[] = formatMonthlyCreatedAccounts(accounts, month);

        handleMonthlyAccounts(formattedAccounts);
    };

    const updateCountryAccounts = (countriesAccounts: ClientCountries) => {
        handleCountryAccounts(countriesAccounts);
    };

    // Get data for payable and trial created accounts over a time period
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data }: { data: ClientCount } = await getData('admin/clients/count', {
                    startingDate: dashboardFilter.startingDate,
                    endingDate: dashboardFilter.endingDate
                });

                const { data: countryData }: { data: ClientCountries } = await getData('admin/clients/count/country', {
                    startingDate: dashboardFilter.startingDate,
                    endingDate: dashboardFilter.endingDate
                });

                updateAccounts(data.totalCreated);
                updateCountryAccounts(countryData);
            } catch (e) {
                console.error(e);
            } finally {
                // reset submit boolean from dashboardFilter context to reset its state and allow future submits
                dashboardFilter.updateSubmit(false);
            }
        };

        if (dashboardFilter.submit) {
            fetchData();
        }
    }, [dashboardFilter.submit]);

    // Get data for yearly and monthly (payable and trial) created accounts over a time period
    useEffect(() => {
        const fetchData = async () => {
            try {
                // YYYY-MM format
                const month = `${dashboardFilter.periodFilter.year?.value}-${dashboardFilter.periodFilter.month?.value}`;

                const { data: yearlyAccounts }: { data: ClientCount } = await getData('admin/clients/count', {
                    startingDate: moment(dashboardFilter.periodFilter.year?.value).startOf('year').format('YYYY-MM-DD'),
                    endingDate: moment(dashboardFilter.periodFilter.year?.value).endOf('year').format('YYYY-MM-DD'),
                    period: 'month'
                });

                const { data: monthlyAccounts }: { data: ClientCount } = await getData('admin/clients/count', {
                    startingDate: moment(month).startOf('month').format('YYYY-MM-DD'),
                    endingDate: moment(month).endOf('month').format('YYYY-MM-DD'),
                    period: 'day'
                });

                updateYearlyAccounts(yearlyAccounts.totalCreatedPerPeriod, dashboardFilter.periodFilter.year?.value as string);
                updateMonthlyAccounts(monthlyAccounts.totalCreatedPerPeriod, month);
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();
    }, [dashboardFilter.periodFilter]);

    return {
        accounts: state.accounts,
        yearlyAccounts: state.yearlyAccounts,
        monthlyAccounts: state.monthlyAccounts,
        countriesAccounts: state.countriesAccounts,
        updateAccounts,
        updateYearlyAccounts,
        updateMonthlyAccounts,
        updateCountryAccounts
    };
};

export default useDashboardClients;
