import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLayout from 'layouts/pageLayout';
import Compose from 'routing/Compose';
interface IPrivateRoute extends RouteProps {
    providers?: React.FC[],
};

const PrivateRoute = ({ component: Component, providers, ...rest }: RouteProps & IPrivateRoute) => {
    const cookie: any = Cookies.get('Auth');
    const { pathname } = useLocation();
    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        if (cookie) {
            const auth = JSON.parse(cookie);
            if (auth && auth.token) {
                setIsConnected(true);
            } else {
                setIsConnected(false);
            }
        } else {
            setIsConnected(false);
        }
    }, [pathname]);

    if (!isConnected) {
        return <Redirect to="/login" />;
    }

    return (
        <PageLayout>
            <Compose providers={providers ?? []}>
                <Route component={Component} {...rest} />
            </Compose>
        </PageLayout>
    );
};

export default PrivateRoute;
