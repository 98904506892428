import Cookies from 'js-cookie';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_URL_API; // the prefix of the URL
axios.defaults.headers.get.Accept = ['*', 'application/json']; // default header for all GET request
axios.defaults.headers.post.Accept = ['*', 'application/json']; // default header for all POST request
axios.defaults.headers.put.Accept = ['*', 'application/json']; // default header for all PUT request

// Parse cookies and return authorization header with cookies
export const authHeader = () => {
    const cookie: any = Cookies.get('Auth');
    if (cookie) {
        const auth = JSON.parse(cookie);

        if (auth && auth?.token) {
            return { Authorization: 'Bearer ' + auth.token, 'Cache-Control': 'no-cache' };
        }
    }
    return { Authorization: 'Bearer ' + null };
};
