import React from 'react';
import { ChartSerie } from 'interfaces/client';
import { useTranslation } from 'react-i18next';
import {
    BarChart,
    Bar,
    BarProps,
    XAxis,
    XAxisProps,
    YAxis,
    YAxisProps,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    TooltipProps,
    Rectangle,
} from 'recharts';
import { DataKey } from 'recharts/types/util/types';
import { checkSomeValueNotInObjectArray } from 'utils/utils';
import 'assets/scss/chart.scss';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

// Adding a custom Props for Legend because LegendProps from lib throws an error
type CustomLegendProps = {
    align?: 'left' | 'center' | 'right';
    verticalAlign?: 'top' | 'middle' | 'bottom';
    iconType?: 'line' | 'plainline' | 'square' | 'rect' | 'circle' | 'cross' | 'diamond' | 'star' | 'triangle' | 'wye';
    margin?: { top?: number; left?: number; right?: number; bottom?: number };
    wrapperStyle?: React.CSSProperties;
};

export type CustomBarChartElement = {
    dataKey: DataKey<any>;
    stackId?: string | number;
    yAxisId?: 'left' | 'right'; // If chart is biaxial, yAxisId in CustomBarChartElement MUST be present (see https://github.com/recharts/recharts/issues/1473)
    fill?: string;
};

export interface MandatoryCustomChartProps {
    series: ChartSerie[];
    bars: CustomBarChartElement[];
}

export interface OptionalCustomChartProps {
    biaxial?: boolean; // If chart is biaxial, yAxisId in CustomBarChartElement MUST be present (see https://github.com/recharts/recharts/issues/1473)
    barProps?: BarProps;
    xAxisProps?: XAxisProps;
    yAxisProps?: YAxisProps;
    legendProps?: CustomLegendProps;
}

// Create a custom tooltip popup on bar hover
const CustomTooltip: React.FC<TooltipProps<string, string>> = ({ active, payload, label }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length && checkSomeValueNotInObjectArray(payload, 'value', 0)) {
        return (
            <div className="custom-tooltip">
                <p className="custom-tooltip__label">{label}</p>
                {payload.map((element, key) => (
                    <p className="custom-tooltip__element" key={key}>{`${t(`chart.tooltip.${element.dataKey}`)} : ${element.value}`}</p>
                ))}
            </div>
        );
    }

    return null;
};

type CustomCursorProps = {
    payload?: Array<Payload>;
    x?: number;
    y?: number;
    height?: number;
    width?: number;
    fill?: string;
    stroke?: string;
};

// Create a custom background cursor on bar hover
const CustomCursor: React.FC<CustomCursorProps> = ({ payload, x, y, width, height, fill, stroke }) => {
    if (payload && payload.length && checkSomeValueNotInObjectArray(payload, 'value', 0)) {
        return <Rectangle fill={fill} stroke={stroke} x={x} y={y} width={width} height={height} />;
    }

    return null;
};

// Documentation : https://recharts.org/en-US/api
const CustomBarChart: React.FC<MandatoryCustomChartProps & OptionalCustomChartProps> = (props) => {
    const { t } = useTranslation();
    const xAxisProps: XAxisProps = {
        dataKey: 'name',
        tick: { fontSize: 12 },
        tickMargin: 15,
        angle: -35,
        interval: 0,
        minTickGap: 0,
        ...props.xAxisProps,
    };
    // hacky width to remove weird left margin. See https://github.com/recharts/recharts/issues/843
    const yAxisProps: YAxisProps = {
        tickMargin: 15,
        tick: { fontSize: 12 },
        ...props.yAxisProps,
    };
    const legendProps: CustomLegendProps = {
        align: 'left',
        iconType: 'square',
        wrapperStyle: { paddingLeft: '40px', paddingTop: '15px' },
        ...props.legendProps,
    };
    return (
        <ResponsiveContainer width="99%" height={250}>
            <BarChart data={props.series}>
                <CartesianGrid vertical={false} />
                <XAxis {...xAxisProps} />
                {props.biaxial ? (
                    <>
                        {props.bars.map((barProps, key) => (
                            <YAxis key={`bar-${key}`} yAxisId={barProps.yAxisId} orientation={barProps.yAxisId} stroke={barProps.fill} {...yAxisProps} />
                        ))}
                    </>
                ) : (
                    <YAxis {...yAxisProps} />
                )}
                <Tooltip content={<CustomTooltip />} cursor={<CustomCursor fill="#9999991A" />} />
                <Legend {...legendProps} formatter={(value: string) => <span>{t(`chart.legend.${value}`)}</span>} />
                {props.bars.map((barProps, key) => (
                    <Bar key={`bar-${key}`} {...barProps} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;
