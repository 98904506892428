import React, { useState } from 'react';
import useDashboardClients from 'hooks/useDashboardClients';
import { useTranslation } from 'react-i18next';
import { DashboardIconCard, RenderChartWithSelect } from 'pages/dashboard/components/DashboardCard';
import { monthlyOptions, yearlyOptions, accountBars } from 'pages/dashboard/data';
import CountryList from 'pages/dashboard/components/CountryList';
import 'assets/scss/dashboard.scss';
import { ReactComponent as Accounts } from 'assets/icons/dashboard/total_accounts.svg';
import { ReactComponent as Payable } from 'assets/icons/dashboard/total_payable.svg';
import { ReactComponent as Trial } from 'assets/icons/dashboard/total_trial.svg';
import { ReactComponent as Active } from 'assets/icons/dashboard/total_active.svg';
import useDashboardFilter from 'hooks/useDashboardFilter';
import CustomBarChart from 'components/CustomChart/CustomBarChart';

const DashboardClients = () => {
    const { t } = useTranslation();
    const data = useDashboardClients();
    const filter = useDashboardFilter();
    const [isHovering, setIsHovering] = useState('total');

    const handleMouseOver = (id: string) => {
        setIsHovering(id);
    };

    const isBackgroundActive = (id: string) => {
        return isHovering === id;
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-section">
                <h3 className="dashboard-section-title">{t('dashboard.clients.accounts.title')}</h3>
                <div className="dashboard-data">
                    <DashboardIconCard
                        backgroundActive={isBackgroundActive('total')}
                        id={'total'}
                        title={t('dashboard.clients.data.total')}
                        data={data.accounts.total}
                        icon={<Accounts />}
                        onMouseOver={() => handleMouseOver('total')}
                    />
                    <DashboardIconCard
                        backgroundActive={isBackgroundActive('payable')}
                        onMouseOver={() => handleMouseOver('payable')}
                        id={'payable'}
                        title={t('dashboard.clients.data.payable')}
                        data={data.accounts.payable}
                        icon={<Payable />}
                    />
                    <DashboardIconCard
                        onMouseOver={() => handleMouseOver('trial')}
                        backgroundActive={isBackgroundActive('trial')}
                        id={'trial'}
                        title={t('dashboard.clients.data.trial')}
                        data={data.accounts.trial}
                        icon={<Trial />}
                    />
                    <DashboardIconCard
                        onMouseOver={() => handleMouseOver('active')}
                        backgroundActive={isBackgroundActive('active')}
                        id={'active'}
                        title={t('dashboard.clients.data.active')}
                        data={data.accounts.active ?? 0}
                        icon={<Active />}
                    />
                </div>
            </div>
            <div className="dashboard-content">
                <div className="dashboard-data">
                    <RenderChartWithSelect
                        title={t('dashboard.clients.accounts.monthly')}
                        options={yearlyOptions()}
                        value={filter.periodFilter.year}
                        onChange={filter.updatePeriodFilter}
                        dataKey="year"
                    >
                        <CustomBarChart bars={accountBars} series={data.yearlyAccounts} />
                    </RenderChartWithSelect>
                    <RenderChartWithSelect
                        title={t('dashboard.clients.accounts.daily')}
                        options={monthlyOptions()}
                        value={filter.periodFilter.month}
                        onChange={filter.updatePeriodFilter}
                        dataKey="month"
                    >
                        <CustomBarChart bars={accountBars} series={data.monthlyAccounts} xAxisProps={{ tick: { fontSize: 9 } }} />
                    </RenderChartWithSelect>
                </div>
            </div>
            <div className="dashboard-section">
                <h3 className="dashboard-section-title">{t('dashboard.clients.countries.title')}</h3>
                <div className="dashboard-content">
                    <div className="dashboard-data">
                        <div className="dashboard-section-container">
                            <h4 className="dashboard-content-title">{t('dashboard.clients.countries.payable')}</h4>
                            <CountryList data={data.countriesAccounts.payable} />
                        </div>
                        <div className="dashboard-section-container">
                            <h4 className="dashboard-content-title">{t('dashboard.clients.countries.trial')}</h4>
                            <CountryList data={data.countriesAccounts.trial} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardClients;
