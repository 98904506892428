import React from 'react';
import moment from 'moment';
import 'assets/scss/pageLayout.scss';

const Footer = () => (
    <footer aria-label='Footer'>
        <p>&copy; {moment().year()} Wassa - All rights reserved</p>
    </footer>
);

export default Footer;
