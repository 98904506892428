import React, { ReactNode } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertProps } from 'react-bootstrap-sweetalert/dist/types';
import RoundedLoader from 'components/Loader/RoundedLoader';
import 'assets/scss/modal.scss';
import 'assets/scss/button.scss';

type CustomProps = {
    id: string,
    content?: ReactNode|string,
    isLoading?: boolean
}

type IVisibleAlert = {
    visibleAlert: SweetAlertProps & CustomProps
}

const Modal = ({
    visibleAlert
}: IVisibleAlert) => {
    const customClass = visibleAlert?.customClass || 'custom-modal';
    const confirmBtnCssClass = visibleAlert?.confirmBtnCssClass || 'btn-main';
    const cancelBtnCssClass = visibleAlert?.cancelBtnCssClass || 'btn-cta';

    return (
        <SweetAlert
            customClass={customClass}
            confirmBtnCssClass={confirmBtnCssClass}
            cancelBtnCssClass={cancelBtnCssClass}
            {...visibleAlert}>
            {visibleAlert?.content &&
                <div className='modal-message'>
                    {visibleAlert?.content}
                </div>
            }
            {
                visibleAlert?.isLoading && <RoundedLoader />
            }
        </SweetAlert>
    );
};

export default Modal;
