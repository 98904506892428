import { CountryAccount } from 'context/dashboardClientsContext';
import React from 'react';
import 'assets/scss/countryList.scss';
import { useTranslation } from 'react-i18next';
import { formatCountriesTable } from 'pages/dashboard/data';
import CountryTable from 'pages/dashboard/components/Table';

type CountryListProps = {
    data: CountryAccount[]
}

const CountryList: React.FC<CountryListProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className='country-list'>
            {data.length === 0
                ? <span>{t('dashboard.clients.countries.empty')}</span>
                : <CountryTable data={formatCountriesTable(t, data)} />
            }
        </div>
    );
};

export default CountryList;
