import React, { createContext, useMemo, useReducer } from "react";
import { MyError } from "interfaces/error";
import { ContextValue, ProviderProps } from "interfaces/context";
import { PluginHistoryCall } from "interfaces/pluginHistoryCalls";

interface ClientPluginHistoryCallsState {
	historyCalls: PluginHistoryCall[] | null;
	error: MyError | null;
	loading: boolean;
	refetch: boolean;
}

const initialState: ClientPluginHistoryCallsState = {
	historyCalls: null,
	error: null,
	loading: false,
	refetch: false,
};

export enum ClientPluginHistoryCallsActionKind {
	HANDLE_PLUGIN_HISTORY_CALLS,
	HANDLE_ERROR,
	HANDLE_REFETCH,
}

type ClientPluginAction =
	| {
			type: ClientPluginHistoryCallsActionKind.HANDLE_PLUGIN_HISTORY_CALLS;
			payload: PluginHistoryCall[];
	  }
	| {
			type: ClientPluginHistoryCallsActionKind.HANDLE_ERROR;
			payload: MyError | null;
	  }
	| {
			type: ClientPluginHistoryCallsActionKind.HANDLE_REFETCH;
			payload: undefined;
	  };

const clientPluginHistoryCallsContext = createContext<
	ContextValue<ClientPluginHistoryCallsState, ClientPluginAction>
>({
	state: initialState,
	dispatch: (action) =>
		console.error(
			"Dispatched action outside of a clientPluginHistoryCallsContext provider",
			action
		),
});

const reducer: React.Reducer<
	ClientPluginHistoryCallsState,
	ClientPluginAction
> = (state, action) => {
	switch (action.type) {
		case ClientPluginHistoryCallsActionKind.HANDLE_PLUGIN_HISTORY_CALLS:
			return {
				...state,
				historyCalls: action.payload,
				refetch: false,
				error: null,
				loading: false,
			};

		case ClientPluginHistoryCallsActionKind.HANDLE_ERROR:
			return {
				...state,
				error: action.payload,
				refetch: false,
				loading: false,
			};

		case ClientPluginHistoryCallsActionKind.HANDLE_REFETCH:
			return {
				...state,
				refetch: true,
			};

		default:
			return state;
	}
};

const ClientPluginHistoryCallsProvider: React.FC<ProviderProps> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const contextValue = useMemo(
		() => ({ state, dispatch }),
		[state, dispatch]
	);

	return (
		<clientPluginHistoryCallsContext.Provider value={contextValue}>
			{children}
		</clientPluginHistoryCallsContext.Provider>
	);
};

export { clientPluginHistoryCallsContext, ClientPluginHistoryCallsProvider };
