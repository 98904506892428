import React, { createContext, ReactNode, useReducer } from 'react';
import { Action } from 'interfaces/action';
import { ConsumptionSerie } from 'interfaces/client';

type TotalCount = {
    type: 'VIDEO' | 'PICTURE';
    size: string;
    totalCount: string;
};

export type CallsPerPeriod = {
    type: 'VIDEO' | 'PICTURE';
    typeOfCall: string | null;
    searchKey: string;
    date: string;
    totalCount: string;
    size: string;
    nbFiles: string;
};

export type ConsumptionData = {
    totalCounts: TotalCount[];
    totalCountsPerPeriod: CallsPerPeriod[];
};

export type TotalAggregates = {
    sum: number;
    totalImage: number;
    totalVideo: number;
};
export type MonthlyAggregates = {
    sum: number;
    totalImage: number;
    totalVideo: number;
};

export type YearlyAggregatesCalendarData = {
    year: string;
    apiCalls: { totalVideo: number; totalImage: number; sum: number; months: Array<{ month: string; totalImage: number; totalVideo: number; sum: number }> };
    consumption: { totalVideo: number; totalImage: number; sum: number; months: Array<{ month: string; totalImage: number; totalVideo: number; sum: number }> };
    identity: {
        totalModels: number;
        totalVectors: number;
        months: Array<{ month: string; totalModels: number; totalVectors: number; sum: number }>;
    };
};

export type IinitialState = {
    refetch: boolean;
    rawConsumption: ConsumptionData | null;
    rawTrialConsumption: ConsumptionData | null;
    consumption: any | null;
    trialConsumption: any | null;
    identity: IdentityData | null;
    error: any;
    loading: boolean;
    apiCallAggregates: { total: TotalAggregates; monthly: MonthlyAggregates } | null;
    consumptionAggregates: { total: TotalAggregates; monthly: MonthlyAggregates } | null;
    calendarAggregates: Array<YearlyAggregatesCalendarData>;
    trialApiCallAggregates: { total: TotalAggregates; monthly: MonthlyAggregates } | null;
    trialConsumptionAggregates: { total: TotalAggregates; monthly: MonthlyAggregates } | null;
    chartsPerMonth: ConsumptionSerie[];
    chartsPerDay: ConsumptionSerie[];
};

export type IdentityData = {
    totalCounts: {
        totalIdentities: string;
        totalVectors: string;
    };
    totalCountsPerPeriod: {
        string: {
            identityCount: string;
            vectorCount: string;
        }[];
    };
};

const initialState: IinitialState = {
    refetch: false,
    error: null,
    rawConsumption: null,
    rawTrialConsumption: null,
    consumption: null,
    trialConsumption: null,
    identity: null,
    loading: false,
    apiCallAggregates: null,
    consumptionAggregates: null,
    calendarAggregates: [],
    trialApiCallAggregates: null,
    trialConsumptionAggregates: null,
    chartsPerMonth: [],
    chartsPerDay: [],
};

const consumptionContext = createContext<{
    state: IinitialState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as IinitialState,
    dispatch: () => null,
});

const reducer = (state: IinitialState, action: Action) => {
    const { type, payload } = action;

    switch (type) {
        case 'INIT_CONSUMPTION':
            return {
                ...state,
                rawConsumption: payload.rawConsumption,
                consumption: payload.consumption,
                trialConsumption: payload.trialConsumption,
                identity: payload.identity,
                apiCallAggregates: payload.apiCallAggregates,
                consumptionAggregates: payload.consumptionAggregates,
                calendarAggregates: payload.calendarAggregates,
                trialApiCallAggregates: payload.trialApiCallAggregates,
                trialConsumptionAggregates: payload.trialConsumptionAggregates,
                chartsPerMonth: payload.chartsPerMonth,
                chartsPerDay: payload.chartsPerDay,
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                error: payload,
            };
        case 'HANDLE_REFETCH': {
            return {
                ...state,
                refetch: !state.refetch,
            };
        }
        case 'HANDLE_LOADING': {
            return {
                ...state,
                loading: payload,
            };
        }
        default:
            return state;
    }
};

const ConsumptionProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <consumptionContext.Provider value={{ state, dispatch }}>{children}</consumptionContext.Provider>;
};

export { consumptionContext, ConsumptionProvider };
