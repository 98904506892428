/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useMemo } from 'react';
import { ContextValue, ProviderProps } from 'interfaces/context';
import { AccountSerie } from 'interfaces/client';

export type Account = {
    total: number;
    payable: number;
    trial: number;
    active?: number;
};

export type CountryAccount = {
    country: string;
    total: string;
}

export type ClientCountries = {
    payable: CountryAccount[];
    trial: CountryAccount[];
}

export enum ActionKind {
    HANDLE_ACCOUNTS,
    HANDLE_YEARLY_ACCOUNTS,
    HANDLE_MONTHLY_ACCOUNTS,
    HANDLE_COUNTRIES_ACCOUNTS
}

export type State = {
    accounts: Account;
    yearlyAccounts: AccountSerie[];
    monthlyAccounts: AccountSerie[];
    countriesAccounts: ClientCountries;
};

const initialState: State = {

    accounts: { total: 0, payable: 0, trial: 0, active: 0 },
    yearlyAccounts: [],
    monthlyAccounts: [],
    countriesAccounts: { payable: [], trial: [] }
};

type Action =
    | { type: ActionKind.HANDLE_ACCOUNTS; payload: Account }
    | { type: ActionKind.HANDLE_YEARLY_ACCOUNTS; payload: AccountSerie[] }
    | { type: ActionKind.HANDLE_MONTHLY_ACCOUNTS; payload: AccountSerie[] }
    | { type: ActionKind.HANDLE_COUNTRIES_ACCOUNTS; payload: ClientCountries }

const dashboardClientsContext = createContext<ContextValue<State, Action>>({
    state: initialState,
    dispatch: (action) => console.error('Dispatched action outside of a dashboardClientsContext provider', action)
});

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionKind.HANDLE_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            };
        case ActionKind.HANDLE_YEARLY_ACCOUNTS:
            return {
                ...state,
                yearlyAccounts: action.payload
            };
        case ActionKind.HANDLE_MONTHLY_ACCOUNTS:
            return {
                ...state,
                monthlyAccounts: action.payload
            };
        case ActionKind.HANDLE_COUNTRIES_ACCOUNTS:
            return {
                ...state,
                countriesAccounts: action.payload
            };
        default:
            return state;
    }
};

const DashboardClientsProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <dashboardClientsContext.Provider value={contextValue}>{children}</dashboardClientsContext.Provider>;
};

export { dashboardClientsContext, DashboardClientsProvider };
