import React, { useCallback, useEffect, useState } from 'react';
import 'assets/scss/dashboard.scss';
import { useTranslation } from 'react-i18next';
import { DashboardCard, DashboardIconCard, RenderChartWithSelect } from 'pages/dashboard/components/DashboardCard';
import { ReactComponent as ImageIcon } from 'assets/icons/dashboard/icon_image.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/dashboard/icon_video.svg';
import CustomPieChart from 'components/CustomChart/CustomPieChart';
import { checkSomeValueNotInObjectArray, convertSize, getByteSize, getByteValue } from 'utils/utils';
import { consumptionBars, dateYearOptions, monthlyOptions, yearlyOptions } from 'pages/dashboard/data';
import useDashboardFilter from 'hooks/useDashboardFilter';
import CustomHeatmap from 'components/CustomChart/CustomHeatmapChart';
import CustomBarChart from 'components/CustomChart/CustomBarChart';
import { Card } from 'components/Cards/Card';
import { Option } from 'interfaces/option';
import { SelectServiceOption, useDashboardConsumptionFilter } from 'hooks/useDashboardConsumptionFilter';
import { Field, useFormikContext } from 'formik';
import AppFormDatePicker from 'components/Form/AppFormDatePicker';
import AppForm from 'components/Form/AppForm';
import AppFormSelectField from 'components/Form/AppFormSelectField';
import moment, { Moment } from 'moment';
import Button from 'components/Button/Button';
import AppFormSubmitButton from 'components/Form/AppFormSubmitButton';
import RoundedLoader from 'components/Loader/RoundedLoader';
import { useDashboardClientConsumption, FiltersState } from 'hooks/useDashboardClientConsumption';
import { useAxios } from 'services/requestService';
import { ClientAggregationConsumption, ConsumptionSerie, HeatMapSerie } from 'interfaces/client';
import { formatClientChartsAggregates, TypeOfHeatmap, insertDailyDataIntoPeriodicDataForServiceUsageSection, prepareHeatMapData } from 'utils/formatData';
import CustomSelect from 'components/Select/Select';

type FiltersAvailables = 'createdAfter' | 'createdBefore' | 'service';

type DashboardFilterProps = {
    filtersAvailables?: FiltersAvailables[];
    serviceOptions?: SelectServiceOption[];
    isLoading: boolean;
    onSubmit: (v: any) => void;
    withButton?: boolean;
};

const DashboardFilters: React.FC<DashboardFilterProps> = ({
    isLoading = true,
    serviceOptions,
    filtersAvailables = ['createdAfter', 'createdBefore', 'service'],
    onSubmit,
    withButton = true,
}) => {
    const { t } = useTranslation();
    const { setValues, initialValues, values, dirty, isValid, submitForm } = useFormikContext();
    const formikValues = values as FiltersState;
    const reset = () => {
        setValues({ dateStart: '', dateEnd: '', service: { label: 'ALL', value: undefined } });
    };
    return (
        <div className="dashboard-section-filter-container">
            <RoundedLoader isLoading={isLoading} />
            <div className="form-fields">
                {filtersAvailables.includes('createdAfter') && (
                    <Field
                        label={t('dashboard.filters.created-after-label')}
                        component={AppFormDatePicker}
                        name="dateStart"
                        max={moment().format('YYYY-MM-DD')}
                    />
                )}
                {filtersAvailables.includes('createdBefore') && (
                    <Field
                        label={t('dashboard.filters.created-before-label')}
                        component={AppFormDatePicker}
                        name="dateEnd"
                        max={moment().format('YYYY-MM-DD')}
                        min={moment(formikValues.dateStart).format('YYYY-MM-DD')}
                    />
                )}
                {filtersAvailables.includes('service') && (
                    <Field
                        component={AppFormSelectField}
                        id="service"
                        name="service"
                        options={serviceOptions}
                        labelName={t('dashboard.filters.service-label')}
                        placeholder={t('dashboard.filters.service-label')}
                    />
                )}
            </div>
            {withButton && (
                <div className="form-actions ml-1">
                    <Button className="btn-cta" title={t('dashboard.filters.reset')} onClick={reset} disabled={!(isValid && dirty)} />
                    <AppFormSubmitButton title={t('dashboard.filters.confirm')} className="btn-main" />
                </div>
            )}
        </div>
    );
};

const APICallsAndDataConsumption = () => {
    const filters = useDashboardConsumptionFilter();

    const initialFormState = {
        service: filters.services[0],
        dateStart: filters.periods.dateStart,
        dateEnd: filters.periods.dateEnd,
    };
    const { individualLoader, getApiCallsAndDataConsumption, getPieChartSerie, submitGetConsumption } = useDashboardClientConsumption({ granularity: 'month' });

    const onSubmit = (v: any) => {
        submitGetConsumption({
            granularity: 'month',
            service: v.service?.label ? undefined : v.service,
            dateStart: v.dateStart !== '' ? v.dateStart : undefined,
            dateEnd: v.dateEnd !== '' ? v.dateEnd : moment().format('YYYY-MM-DD'),
        });
    };

    const { t } = useTranslation();
    const cardResult = getApiCallsAndDataConsumption();
    const chartResult = getPieChartSerie();

    return (
        <section className="dashboard-container">
            <Card cardType="shadowed">
                <div className="d-flex justify-content-between">
                    <h4 className="dashboard-section-title mb-3">{t('dashboard.consumption.calls.title')}</h4>
                    <AppForm enableReinitialize initialValues={initialFormState} onSubmit={onSubmit}>
                        <DashboardFilters serviceOptions={filters.services} isLoading={individualLoader} onSubmit={onSubmit} />
                    </AppForm>
                </div>
                <Card cardType="shadowed" cardRadius="squared" classNames="mb-4">
                    <div className="dashboard-content">
                        <div className="d-flex align-items-center">
                            <h4 className="dashboard-content-title">{t('dashboard.consumption.calls.section')}</h4>
                            <p className="mb-0 ml-2 color-primary dashbord-content-total">({cardResult?.apiCallAggregates.total.sum})</p>
                        </div>
                        <div className="dashboard-data">
                            <DashboardIconCard
                                title={t('dashboard.consumption.calls.images')}
                                data={cardResult?.apiCallAggregates.total.totalImage}
                                color="#f5c60a"
                                subtitle={t('dashboard.consumption.calls.subtitle').toUpperCase()}
                                icon={<ImageIcon fill="#f5c60a" />}
                            />
                            <DashboardIconCard
                                title={t('dashboard.consumption.calls.videos')}
                                data={cardResult?.apiCallAggregates.total.totalVideo}
                                color="#0acef5"
                                subtitle={t('dashboard.consumption.calls.subtitle').toUpperCase()}
                                icon={<VideoIcon fill="#0acef5" />}
                            />
                            <DashboardCard withBorder={false} style={{ height: '120px' }}>
                                <CustomPieChart
                                    data={chartResult.apiCalls}
                                    pieProps={{ cx: '30%', innerRadius: 30, outerRadius: 50, labelLine: true }}
                                    legendProps={{ wrapperStyle: { right: '20%' } }}
                                    withPercentageLabel
                                />
                            </DashboardCard>
                        </div>
                    </div>
                </Card>
                <Card cardType="shadowed" cardRadius="squared" classNames="mb-2">
                    <div className="dashboard-content">
                        <div className="d-flex align-items-center">
                            <h4 className="dashboard-content-title mr-3">{t('dashboard.consumption.consumption.section')}</h4>
                            <p className="mb-0 ml-2 color-primary dashbord-content-total">({convertSize(cardResult?.consumptionAggregates.total.sum ?? 0)})</p>
                        </div>
                        <div className="dashboard-data">
                            <DashboardIconCard
                                title={t('dashboard.consumption.consumption.images')}
                                data={convertSize(cardResult?.consumptionAggregates.total.totalImage ?? 0)}
                                color="#f5c60a"
                                icon={<ImageIcon fill="#f5c60a" />}
                            />
                            <DashboardIconCard
                                title={t('dashboard.consumption.consumption.videos')}
                                data={convertSize(cardResult?.consumptionAggregates.total.totalVideo ?? 0)}
                                color="#0acef5"
                                icon={<VideoIcon fill="#0acef5" />}
                            />
                            <DashboardCard withBorder={false} style={{ height: '120px' }}>
                                <CustomPieChart
                                    data={chartResult.consumption}
                                    pieProps={{ cx: '30%', innerRadius: 30, outerRadius: 50, labelLine: true }}
                                    legendProps={{ wrapperStyle: { right: '20%' } }}
                                    withPercentageLabel
                                />
                            </DashboardCard>
                        </div>
                    </div>
                </Card>
            </Card>
        </section>
    );
};

const ServiceRepartition = () => {
    const { t } = useTranslation();
    const filters = useDashboardConsumptionFilter();

    const initialFormState = {
        dateStart: filters.periods.dateStart,
        dateEnd: filters.periods.dateEnd,
    };
    const { individualLoader, getServiceRepartitionPieChartSerie, submitGetConsumption } = useDashboardClientConsumption({ granularity: 'month' });
    const onSubmit = (v: any) => {
        submitGetConsumption({
            granularity: 'month',
            dateStart: v.dateStart !== '' ? v.dateStart : undefined,
            dateEnd: v.dateEnd !== '' ? v.dateEnd : moment().format('YYYY-MM-DD'),
        });
    };
    const data = getServiceRepartitionPieChartSerie();
    return (
        <section className="dashboard-container">
            <Card cardType="shadowed" cardRadius="rounded">
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="dashboard-section-title">{t('dashboard.consumption.service-repartition.title')}</h3>
                    <AppForm enableReinitialize initialValues={initialFormState} onSubmit={onSubmit}>
                        <DashboardFilters isLoading={individualLoader} filtersAvailables={['createdAfter', 'createdBefore']} onSubmit={onSubmit} />
                    </AppForm>
                </div>
                <Card cardType="shadowed" cardRadius="squared">
                    <div className="dashboard-data">
                        <DashboardCard>
                            <div className="dashboard-consumption-piechart">
                                <h4>{t('dashboard.consumption.service-repartition.calls')}</h4>
                                <CustomPieChart
                                    data={data?.apiCalls ?? []}
                                    pieProps={{ cx: '30%', innerRadius: 55, outerRadius: 80 }}
                                    legendProps={{ layout: 'vertical', iconType: 'circle', iconSize: 12 }}
                                    withPercentageLegend
                                    withActiveText
                                    textSubtitle={t('dashboard.consumption.service-repartition.calls')}
                                />
                            </div>
                        </DashboardCard>
                        <DashboardCard>
                            <div className="dashboard-consumption-piechart">
                                <h4>{t('dashboard.consumption.service-repartition.consumption')}</h4>
                                <CustomPieChart
                                    data={data?.consumption ?? []}
                                    pieProps={{ cx: '30%', innerRadius: 55, outerRadius: 80 }}
                                    legendProps={{ layout: 'vertical', iconType: 'circle', iconSize: 12 }}
                                    withPercentageLegend
                                    withActiveText
                                    textSubtitle={t('dashboard.consumption.service-repartition.consumption')}
                                />
                            </div>
                        </DashboardCard>
                    </div>
                </Card>
            </Card>
        </section>
    );
};

const HeatMapSection: React.FC = () => {
    const { t } = useTranslation();
    const { getData2, individualLoader } = useAxios();
    const [heatmapFilter, setHeatmapFilter] = useState({ label: moment().year().toString(), value: moment().format('YYYY-MM-DD') });
    const [heatmapData, setHeatMapData] = useState<TypeOfHeatmap>({
        API_CALLS: { data: [], legendData: { average: 0 } },
        CONSUMPTION: { data: [], legendData: { average: 0 } },
    } as TypeOfHeatmap);
    const { values, setValues } = useFormikContext();

    const getDateEnd = () => {
        const date = moment(heatmapFilter.value).endOf('year');
       
        if (date.isAfter(moment())) {
            return moment().format('YYYY-MM-DD');
        }

        return date.format('YYYY-MM-DD');
    };

    const getHeatMapData = useCallback(
        async (values) => {
            const dateStart = moment(heatmapFilter.value, 'YYYY-MM').startOf('year').format('YYYY-MM-DD');

            const { data: consumption } = await getData2<ClientAggregationConsumption>('admin/clients/consumption', {
                granularity: 'day',
                dateStart: dateStart,
                dateEnd: getDateEnd(),
                service: values.service,
            });
            const response = consumption as ClientAggregationConsumption;
            const formattedData = insertDailyDataIntoPeriodicDataForServiceUsageSection(
                response?.prevAggregates,
                'day',
                heatmapFilter.value,
                response?.dailyAggregates
            );
            const data = prepareHeatMapData(formattedData, heatmapFilter.label);
            setHeatMapData(data);
        },
        [heatmapFilter, values]
    );
    useEffect(() => {
        getHeatMapData(values);
    }, [getHeatMapData]);

    const getSelectedData = () => {
        let data: any = [];
        let average: number | string = 0;
        let dataType = '';
        if ((values as any).typeOfAggregates.value === 'CONSUMPTION') {
            data = heatmapData['CONSUMPTION'].data;
            average = getByteValue(heatmapData['CONSUMPTION'].legendData.average);
            dataType = getByteSize(heatmapData['CONSUMPTION'].legendData.average);
        }
        if ((values as any).typeOfAggregates.value === 'API_CALLS') {
            data = heatmapData['API_CALLS'].data;
            average = Math.round(heatmapData['API_CALLS'].legendData.average);
            dataType = 'Calls';
        }
        return {
            data,
            average,
            dataType,
        };
    };

    const typeOfAggregatesOptions: Option[] = [
        { label: 'Api calls', value: 'API_CALLS' },
        { label: 'Consumption', value: 'CONSUMPTION' },
    ];

    const onChangeType = (e: any) => {
        setValues({ ...(values as any), typeOfAggregates: e });
    };

    return (
        <Card cardType="shadowed" cardRadius="squared" classNames="mb-4">
            <div className="dashboard-data">
                <RenderChartWithSelect
                    title={t('dashboard.consumption.usage.yearly')}
                    options={dateYearOptions()}
                    value={heatmapFilter}
                    onChange={setHeatmapFilter}
                    dataKey="year"
                >
                    <div className="d-flex justify-content-end">
                        <CustomSelect placeholder={'Type'} options={typeOfAggregatesOptions} value={(values as any).typeOfAggregates} onChange={onChangeType} />
                    </div>
                    <RoundedLoader isLoading={individualLoader} />
                    <CustomHeatmap data={getSelectedData().data} average={getSelectedData().average} squareSize={13} dataType={getSelectedData().dataType} />
                </RenderChartWithSelect>
            </div>
        </Card>
    );
};

const ServicesGraphSection = () => {
    const { t } = useTranslation();
    const { getData2 } = useAxios();
    const { values } = useFormikContext();
    const [periodFilter, setPeriodFilter] = useState({
        year: { label: moment().format('YYYY'), value: moment().format('YYYY') },
        month: { label: moment().format('MMMM'), value: moment().format('MM') },
    });
    const [chartData, setChartData] = useState<{ chartsPerMonth: ConsumptionSerie[]; chartsPerDay: ConsumptionSerie[] }>({
        chartsPerDay: [],
        chartsPerMonth: [],
    });

    const handleChangePeriod = (newOption: Option, dataKey: 'year' | 'month') => {
        setPeriodFilter({ ...periodFilter, [dataKey]: newOption });
    };

    const getDateEnd = (year: string, month: string) => {
        const date = moment(`${year}-${month}`).endOf('year');
       
        if (date.isAfter(moment())) {
            return moment().format('YYYY-MM-DD');
        }

        return date.format('YYYY-MM-DD');
    };

    const getGraphsData = useCallback(
        async (periodFilter) => {
            const year = periodFilter.year.label;
            const month = periodFilter.month.label;
            const dateStart = moment(`${year}-${month}`, 'YYYY-MM').startOf('year').format('YYYY-MM-DD');

            const { data: consumption } = await getData2<ClientAggregationConsumption>('admin/clients/consumption', {
                granularity: 'day',
                dateStart: dateStart,
                dateEnd: getDateEnd(year, month),
                service: (values as any).service,
            });

            const chartsAggregates = formatClientChartsAggregates(
                consumption as ClientAggregationConsumption,
                periodFilter.year.value,
                periodFilter.month.value
            );
            setChartData(chartsAggregates);
        },
        [periodFilter, values]
    );

    useEffect(() => {
        getGraphsData(periodFilter);
    }, [getGraphsData]);

    return (
        <Card cardType="shadowed" cardRadius="squared">
            <div className="dashboard-data">
                <RenderChartWithSelect
                    title={t('dashboard.consumption.usage.monthly')}
                    options={yearlyOptions()}
                    value={periodFilter.year}
                    onChange={handleChangePeriod}
                    dataKey="year"
                >
                    <CustomBarChart bars={consumptionBars} series={chartData.chartsPerMonth} biaxial />
                </RenderChartWithSelect>
                <RenderChartWithSelect
                    title={t('dashboard.consumption.usage.daily')}
                    options={monthlyOptions()}
                    value={periodFilter.month}
                    onChange={handleChangePeriod}
                    dataKey="month"
                >
                    <CustomBarChart bars={consumptionBars} series={chartData.chartsPerDay} biaxial xAxisProps={{ tick: { fontSize: 9 } }} />
                </RenderChartWithSelect>
            </div>
        </Card>
    );
};

const ServiceUsage = () => {
    const { t } = useTranslation();
    const filters = useDashboardConsumptionFilter();

    const initialFormState = {
        service: filters.services[0].value,
        typeOfAggregates: { label: 'Api calls', value: 'API_CALLS' },
    };
    // dirty but not the time to modify the AppForm component to make onSubmit optional
    const onSubmit = (v: FiltersState) => { };

    return (
        <section className="dashboard-section">
            <AppForm enableReinitialize initialValues={initialFormState} onSubmit={onSubmit}>
                <Card cardType="shadowed" cardRadius="rounded">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="dashboard-section-title">{t('dashboard.consumption.usage.title')}</h3>
                        <div className="d-flex align-items-center">
                            <DashboardFilters
                                serviceOptions={filters.services}
                                isLoading={false}
                                onSubmit={onSubmit}
                                filtersAvailables={['service']}
                                withButton={false}
                            />
                        </div>
                    </div>
                    <HeatMapSection />
                    <ServicesGraphSection />
                </Card>
            </AppForm>
        </section>
    );
};

const DashboardConsumption = () => {
    return (
        <div className="dashboard-container">
            <APICallsAndDataConsumption />
            <ServiceRepartition />
            <ServiceUsage />
        </div>
    );
};

export default DashboardConsumption;
