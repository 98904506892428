import React, { ReactNode } from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useModal from 'components/Modal/hook/useModal';
import Modal from 'components/Modal/Modal';
import 'assets/scss/pageLayout.scss';

/*
 Main layout composed of an Header, a Sidebar & a Footer
*/

const PageLayout: React.FC = ({ children }: { children?: ReactNode }) => {
    const { width } = useWindowDimensions();
    const { visibleAlert } = useModal();

    return (
        <>
            <Sidebar />
            <Header />
            <div className={`page-container py-3 ${width >= 991 ? 'px-4' : ''}`}>
                <div className='container-fluid'>
                    {children}
                </div>
                <Footer />
            </div>
            {visibleAlert && <Modal visibleAlert={visibleAlert} />}
        </>
    );
};

export default PageLayout;
