/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useMemo } from 'react';
import { ContextValue, ProviderProps } from 'interfaces/context';
import { ConsumptionSerie, HeatMapSerie, PieChartSerie } from 'interfaces/client';
import { formatInitialCallsAndConsumptionByService } from 'utils/formatData';

export enum ActionKind {
    HANDLE_PERIOD_FILTER,
    HANDLE_CALLS_COUNT,
    HANDLE_CONSUMPTION_COUNT,
    HANDLE_CALLS_BY_SERVICE,
    HANDLE_CONSUMPTION_BY_SERVICE,
    HANDLE_CONSUMPTION_HEATMAP,
    HANDLE_CONSUMPTION_YEAR,
    HANDLE_CONSUMPTION_MONTH,
}

export type State = {
    callsCount: PieChartSerie[];
    consumptionCount: PieChartSerie[];
    callsByService: PieChartSerie[];
    consumptionByService: PieChartSerie[];
    consumptionHeatmap: HeatMapSerie[];
    yearlyConsumption: ConsumptionSerie[];
    monthlyConsumption: ConsumptionSerie[];
};

const initialState: State = {
    callsCount: [
        { name: 'videos', value: 0, textColor: '#FFF', cellColor: '#0acef5' },
        { name: 'images', value: 0, textColor: '#FFF', cellColor: '#f5c60a' }
    ],
    consumptionCount: [
        { name: 'videos', value: 0, textColor: '#FFF', cellColor: '#0acef5' },
        { name: 'images', value: 0, textColor: '#FFF', cellColor: '#f5c60a' }
    ],
    callsByService: formatInitialCallsAndConsumptionByService(),
    consumptionByService: formatInitialCallsAndConsumptionByService(),
    consumptionHeatmap: [],
    yearlyConsumption: [],
    monthlyConsumption: []
};

type Action =
    | { type: ActionKind.HANDLE_CALLS_COUNT; payload: PieChartSerie[] }
    | { type: ActionKind.HANDLE_CONSUMPTION_COUNT; payload: PieChartSerie[] }
    | { type: ActionKind.HANDLE_CALLS_BY_SERVICE; payload: PieChartSerie[] }
    | { type: ActionKind.HANDLE_CONSUMPTION_BY_SERVICE; payload: PieChartSerie[] }
    | { type: ActionKind.HANDLE_CONSUMPTION_HEATMAP; payload: HeatMapSerie[] }
    | { type: ActionKind.HANDLE_CONSUMPTION_YEAR; payload: ConsumptionSerie[] }
    | { type: ActionKind.HANDLE_CONSUMPTION_MONTH; payload: ConsumptionSerie[] }

const dashboardConsumptionContext = createContext<ContextValue<State, Action>>({
    state: initialState,
    dispatch: (action) => console.error('Dispatched action outside of a dashboardConsumptionContext provider', action)
});

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionKind.HANDLE_CALLS_COUNT:
            return {
                ...state,
                callsCount: action.payload
            };
        case ActionKind.HANDLE_CONSUMPTION_COUNT:
            return {
                ...state,
                consumptionCount: action.payload
            };
        case ActionKind.HANDLE_CALLS_BY_SERVICE:
            return {
                ...state,
                callsByService: action.payload
            };
        case ActionKind.HANDLE_CONSUMPTION_BY_SERVICE:
            return {
                ...state,
                consumptionByService: action.payload
            };
        case ActionKind.HANDLE_CONSUMPTION_HEATMAP:
            return {
                ...state,
                consumptionHeatmap: action.payload
            };
        case ActionKind.HANDLE_CONSUMPTION_YEAR:
            return {
                ...state,
                yearlyConsumption: action.payload
            };
        case ActionKind.HANDLE_CONSUMPTION_MONTH:
            return {
                ...state,
                monthlyConsumption: action.payload
            };
        default:
            return state;
    }
};

const DashboardConsumptionProvider: React.FC<ProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <dashboardConsumptionContext.Provider value={contextValue}>{children}</dashboardConsumptionContext.Provider>;
};

export { dashboardConsumptionContext, DashboardConsumptionProvider };
