import React from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';

/*
 Custom Formik form
*/

interface CustomProps {
    className?: string;
}

const AppForm = ({ initialValues, enableReinitialize, onSubmit, validationSchema, children, className }: FormikConfig<FormikValues> & CustomProps) => (
    <Formik enableReinitialize={enableReinitialize} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {() => <Form className={className}>{children}</Form>}
    </Formik>
);

export default AppForm;
