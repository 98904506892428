import React from 'react';
import Routes from 'routing/Routes';

function App () {
    return (
        <main className='App'>
            <Routes />
        </main>
    );
}

export default App;
