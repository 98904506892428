import { BlockList } from 'interfaces/blockList';
import { ContextValue, ProviderProps } from 'interfaces/context';
import { PaginationResponse } from 'interfaces/pagination';
import React, { createContext, useMemo, useReducer } from 'react';

export const blockListSortKeys = [
    "domain",
    "uuid",
    "created_at",
    "updated_at",
] as const;
export type BlockListSortKey = (typeof blockListSortKeys)[number];

export interface BlockListSort {
    sortBy: BlockListSortKey,
    sortOrder: 'ASC' | 'DESC'
};

export interface PaginationInput {
    page: number,
    elementPerPage: number
};

export interface BlockListFilters {
    domain?: string,
    dateStart?: string,
    dateEnd?: string,
};

// TODO terminer le typage

interface BlockListState {
    data: PaginationResponse<BlockList[]> | null,
    isLoading: boolean,
    isError: boolean,
    error: any,
    sort: BlockListSort,
    paginationInput: PaginationInput
    filter: BlockListFilters | null
};

const initialValues: BlockListState = {
    data: null,
    isLoading: false,
    isError: false,
    error: null,
    sort: {
        sortBy: 'domain',
        sortOrder: 'DESC'
    },
    paginationInput: {
        page: 1,
        elementPerPage: 10
    },
    filter: null
};

export enum BlockListActionEnum {
    SET_LOADING,
    SET_DATA,
    SET_IS_ERROR,
    SET_ERROR,
    SET_SORT,
    SET_PAGINATION_INPUT,
    SET_FILTERS,
}

export type BlockListAction =
    | { type: BlockListActionEnum.SET_LOADING; payload: boolean }
    | { type: BlockListActionEnum.SET_DATA; payload: PaginationResponse<BlockList[]> | null }
    | { type: BlockListActionEnum.SET_IS_ERROR; payload: boolean }
    | { type: BlockListActionEnum.SET_ERROR; payload: any }
    | { type: BlockListActionEnum.SET_SORT; payload: BlockListSort }
    | { type: BlockListActionEnum.SET_PAGINATION_INPUT; payload: PaginationInput }
    | { type: BlockListActionEnum.SET_FILTERS; payload: BlockListFilters | null }

const blockListContext = createContext<ContextValue<BlockListState, BlockListAction>>({ state: initialValues, dispatch: (action) => console.error('Dispatched action outside of a demoContext provider', action) });

const reducer = (state: BlockListState, action: BlockListAction): BlockListState => {
    const { type, payload } = action;

    switch (type) {
        case BlockListActionEnum.SET_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }
        case BlockListActionEnum.SET_DATA: {
            return {
                ...state,
                data: payload
            };
        }
        case BlockListActionEnum.SET_IS_ERROR: {
            return {
                ...state,
                isError: payload
            };
        }
        case BlockListActionEnum.SET_ERROR: {
            return {
                ...state,
                error: payload
            };
        }
        case BlockListActionEnum.SET_SORT: {
            return {
                ...state,
                sort: payload
            };
        }
        case BlockListActionEnum.SET_PAGINATION_INPUT: {
            return {
                ...state,
                paginationInput: payload
            };
        }
        case BlockListActionEnum.SET_FILTERS: {
            return {
                ...state,
                filter: payload
            };
        }
        default: return state;
    }
};

const BlockListProvider: React.FC<ProviderProps> = ({
    children
}) => {
    const [state, dispatch] = useReducer(reducer, initialValues);
    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <blockListContext.Provider value={contextValue}>
            {children}
        </blockListContext.Provider>
    );
};

export { blockListContext, BlockListProvider };
